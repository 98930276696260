import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllPodcasts } from '../apiCalls/podcasts/api_v1_podcasts';

function GamezGuides({skillLevel}) {
    const navigate = useNavigate();
    const [guides, setGuides] = useState([]);
    const [loading, setLoading] = useState(false);
    const path = window.location.pathname;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const gameUuid = queryParams.get('uuid');

    useEffect(() => {
        const fetchStories = async () => {
            try {
                // let skillLevel = localStorage.getItem("skillLevel");
                if (path=='/game' && ( gameUuid != null || gameUuid != undefined || gameUuid != "")) {
                    // console.log(gameUuid);
                    const response = await getAllPodcasts({"uuid": gameUuid, "skillLevel": skillLevel});
                    if (response.status === 200) {
                        setGuides(response.data.data.podcasts);
                    }
                }
                else {
                    console.log(skillLevel)
                    const response = await getAllPodcasts({"skillLevel": skillLevel});
                    if (response.status === 200) {
                        setGuides(response.data.data.podcasts);
                        // console.log(response.data.data.podcasts)
                    }
                }
                
            } catch (error) {
                console.error('Error fetching stories:', error);
            }
        };
        fetchStories();
    }, [skillLevel]); 

    const fetchMoreGuides = async () => {
        setLoading(true);
        try {
            const response = await getAllPodcasts({"skillLevel": skillLevel});
            if (response.status === 200) {
                setGuides(prevGuides => [...prevGuides, ...response.data.data.podcasts]);
            }
        } catch (error) {
            console.error('Error fetching more guides:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleGuideClick = (uuid) => {
        // console.log("FROM PODCAST SCREEN " + uuid)
        navigate(`/guides?uuid=${uuid}`);
    };

    return (
        <Box
            sx={{
                p: path === '/guides' ? 0 : 2,
                color: 'white',
                maxWidth: '800px',
            }}
        >
            <Typography variant="h6" gutterBottom>
                Gamez Guides
            </Typography>
            <Grid container spacing={2}>
                {guides.map((guide) => (
                    <Grid
                        item
                        key={guide.uuid}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        onClick={() => handleGuideClick(guide.uuid)}
                        sx={{ cursor: 'pointer' }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                backgroundColor: '#1e1e1e',
                                borderRadius: '12px',
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                component="img"
                                loading='lazy'
                                src={guide.thumbnail}
                                sx={{
                                    width: '180px',
                                    height: '120px',
                                    borderRadius: '8px',
                                    objectFit: 'cover',
                                    marginRight: '16px',
                                }}
                            />
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: '500',
                                    fontSize: '1rem',
                                    color: 'white',
                                    textAlign: 'start',
                                }}
                            >
                                {guide.title}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Button
                onClick={fetchMoreGuides}
                sx={{
                    mt: 2,
                    color: 'grey.500',
                    textTransform: 'none',
                    display: 'block',
                    width: '100%',
                }}
                disabled={loading}
            >
                {loading ? 'Loading...' : 'More Guides'}
            </Button>
        </Box>
    );
}

export default GamezGuides;