import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { getAllShorts } from '../apiCalls/shorts/api_v1_shorts';
import { useLocation, useNavigate } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/autoplay';

function GamezBites({skillLevel}) {
    const [shorts, setShorts] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const gameUuid = queryParams.get('uuid');
    const path = window.location.pathname;

    useEffect(() => {
        const fetchShorts = async () => {
            setLoading(true);
            try {
                // console.log("Fetching shorts for skill level:", skillLevel);
                
                if (path === '/game' && gameUuid) {
                    // console.log("SENDING GAME REQ for skillLevel:", skillLevel);
                    const response = await getAllShorts({"uuid": gameUuid, "skillLevel": skillLevel});
                    if (response.status === 200) {
                        setShorts(response.data.data.shorts);
                    }
                }
                else {
                    const response = await getAllShorts({"skillLevel": skillLevel});
                    if (response.status === 200) {
                        setShorts(response.data.data.shorts);
                        // console.log(response)
                    }
                }
            } catch (error) {
                console.error('Error fetching shorts:', error);
                setShorts([]);
            } finally {
                setLoading(false);
            }
        };
        
        fetchShorts();
    }, [skillLevel]); 

    const handleShortClick = (shortUuid) => {
        navigate(`/bites?short=${shortUuid}`);
    };

    return (
        <Box sx={{ p: 2, pt:0, color: 'white', maxWidth: '100%'}}>
            <Typography variant="h6" gutterBottom>
                Gamez Bites
            </Typography>

            {loading ? (
                <Typography variant="body2" color="text.secondary">
                    Loading content...
                </Typography>
            ) : shorts.length === 0 ? (
                <Typography variant="body2" color="text.secondary">
                    No bites available for this skill level.
                </Typography>
            ) : (
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={16}
                    slidesPerView="auto"
                    loop={shorts.length > 1}
                    autoplay={shorts.length > 1 ? { delay: 2500, disableOnInteraction: false } : false}
                    style={{ width: '100%' }}
                    breakpoints={{
                        0: { slidesPerView: 2 },
                        600: { slidesPerView: 3 },
                        960: { slidesPerView: 4 },
                        1280: { slidesPerView: 5 },
                    }}
                >
                    {shorts.map((short) => (
                        <SwiperSlide
                            key={short.uuid}
                            style={{ maxWidth: '200px', flex: '0 0 auto' }}
                            onClick={() => handleShortClick(short.uuid)}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: '100%',
                                    paddingTop: '177.78%',
                                    borderRadius: '16px',
                                    overflow: 'hidden',
                                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.5)',
                                    backgroundColor: '#252525',
                                    textAlign: 'center',
                                }}
                            >
                                <img
                                    loading='lazy'
                                    src={short.thumbnail}
                                    alt={`Short thumbnail`}
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: 'inherit',
                                    }}
                                />
                            </Box>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </Box>
    );
}

export default GamezBites;