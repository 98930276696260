import React, { useEffect, useState, useRef } from 'react';
import { Box, CircularProgress, IconButton, Typography, useTheme, useMediaQuery, Dialog, DialogContent, Grid, styled } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Mousewheel } from 'swiper/modules';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  PlayArrow,
  FavoriteBorder,
  Favorite,
  Share,
  ContentCopy,
  WhatsApp,
  Instagram,
  Facebook,
  X,
  More
} from '@mui/icons-material';
import { getAllShorts, getSingleShort, likeCurrentShort } from "../apiCalls/shorts/api_v1_shorts";

import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/mousewheel';

const ShareButton = ({ icon: Icon, label, onClick, color = 'white' }) => (
  <Box
    onClick={onClick}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      p: 1,
      '&:hover': {
        opacity: 0.8
      }
    }}
  >
    <IconButton sx={{ color, mb: 0.5 }}>
      <Icon />
    </IconButton>
    <Typography variant="caption" sx={{ color: 'white' }}>
      {label}
    </Typography>
  </Box>
);

const Shorts = () => {
  const [shorts, setShorts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [pausedVideos, setPausedVideos] = useState({});
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [currentShortUrl, setCurrentShortUrl] = useState('');
  const [liking, setLiking] = useState(false);
  const videoRefs = useRef({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shortUuid = queryParams.get("short");
  const skillLevel = sessionStorage.getItem("skillLevel") || "beginner";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchMoreShorts = async () => {
    if (loadingMore) return;
    try {
      setLoadingMore(true);
      const response = await getAllShorts({ skillLevel });
      if (response.data.success) {
        const newShorts = response.data.data.shorts
          .filter(newShort => !shorts.some(existingShort => existingShort.uuid === newShort.uuid))
          .map(short => ({
            ...short,
            like: short.is_liked ? 1 : 0
          }));
        setShorts(prevShorts => [...prevShorts, ...newShorts]);
      }
    } catch (error) {
      console.error("Error fetching more shorts:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  const handleLike = async (event, index) => {
    event.stopPropagation(); 
    if (liking) return;

    try {
      setLiking(true);
      const currentShort = shorts[index];
      const newLikeStatus = currentShort.like === 1 ? 0 : 1;

      const response = await likeCurrentShort({
        shortId: currentShort.uuid,
        like: newLikeStatus
      });

      if (response.data.success) {
        setShorts(prevShorts => {
          const newShorts = [...prevShorts];
          newShorts[index] = {
            ...currentShort,
            like: newLikeStatus
          };
          return newShorts;
        });
      }
    } catch (error) {
      console.error('Error liking short:', error);
    } finally {
      setLiking(false);
    }
  };

  const handleShare = () => {
    const currentShort = shorts[activeIndex];
    const url = currentShort?.uuid
      ? `${window.location.origin}${window.location.pathname}?short=${currentShort.uuid}`
      : window.location.href;
    setCurrentShortUrl(url);
    setShareDialogOpen(true);
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(currentShortUrl);
      setShareDialogOpen(false);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleWhatsAppShare = () => {
    window.open(`https://wa.me/?text=${encodeURIComponent(currentShortUrl)}`, '_blank');
    setShareDialogOpen(false);
  };

  const handleInstagramShare = (type) => {
      window.open(`https://instagram.com/direct/new?url=${encodeURIComponent(currentShortUrl)}`, '_blank');
    setShareDialogOpen(false);
  };

  const handleXShare = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentShortUrl)}`, '_blank');
    setShareDialogOpen(false);
  };

  const playFirstVideo = async () => {
    const firstVideo = videoRefs.current[0];
    if (firstVideo && firstLoad) {
      try {
        await firstVideo.play();
        setFirstLoad(false);
      } catch (error) {
        console.warn('Autoplay failed:', error);
      }
    }
  };

  const handleVideoLoaded = (index) => {
    if (index === 0) {
      playFirstVideo();
    }
  };

  const handleVideoTap = async (index) => {
    const video = videoRefs.current[index];
    if (!video) return;

    if (video.paused) {
      await video.play();
      setPausedVideos(prev => ({ ...prev, [index]: false }));
    } else {
      video.pause();
      setPausedVideos(prev => ({ ...prev, [index]: true }));
    }
  };

  const handleSlideChange = (swiper) => {
    Object.values(videoRefs.current).forEach(video => {
      if (video) video.pause();
    });

    const activeVideo = videoRefs.current[swiper.activeIndex];
    if (activeVideo) {
      activeVideo.play().catch(() => { });
    }
    setActiveIndex(swiper.activeIndex);

    if (swiper.activeIndex >= shorts.length - 3) {
      fetchMoreShorts();
    }
  };

  useEffect(() => {
    document.title = 'Game Bites | Gamez Academy';
  })

  useEffect(() => {
    const fetchInitialShorts = async () => {
      try {
        let shortsArray = [];

        if (shortUuid) {
          const currentShortResponse = await getSingleShort(shortUuid);
          if (currentShortResponse.data.data) {
            shortsArray.push({
              uuid: currentShortResponse.data.data.uuid,
              video: currentShortResponse.data.data.video,
              thumbnail: currentShortResponse.data.data.thumbnail,
              like: currentShortResponse.data.data.is_liked ? 1 : 0,
              skillLevel,
            });
          }
        }

        const allShortsResponse = await getAllShorts({ skillLevel });
        if (allShortsResponse.data.success) {
          const remainingShorts = allShortsResponse.data.data.shorts
            .filter((short) => short.uuid !== shortUuid)
            .map(short => ({
              ...short,
              like: short.is_liked ? 1 : 0
            }));
          shortsArray = [...shortsArray, ...remainingShorts];
        }

        setShorts(shortsArray);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching shorts:", error);
        setLoading(false);
      }
    };

    fetchInitialShorts();

    return () => {
      Object.values(videoRefs.current).forEach(video => {
        if (video) video.pause();
      });
    };
  }, [shortUuid, skillLevel]);

  if (loading) {
    return (
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1000
      }}>
        <CircularProgress sx={{ color: '#FFD700' }} />
      </Box>
    );
  }

  const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
    color: 'white',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
    '& .MuiSvgIcon-root': {
      transition: 'all 0.3s ease',
    }
  }));

  return (
    <Box sx={{
      width: '100%',
      maxWidth: '460px',
      height: '100%',
      margin: '0 auto',
      touchAction: 'manipulation'
    }}>
      <Swiper
        modules={[Virtual, Mousewheel]}
        direction="vertical"
        virtual
        mousewheel={{
          sensitivity: 1,
          thresholdDelta: 50
        }}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        watchSlidesProgress
        style={{ height: '100%' }}
      >
        {shorts.map((short, index) => (
          <SwiperSlide key={short.uuid} virtualIndex={index}>
            <Box sx={{
              height: '100%',
              width: '100%',
              position: 'relative'
            }}>
              <Box
                onClick={() => handleVideoTap(index)}
                sx={{ height: '100%', width: '100%', position: 'relative' }}
              >
                <video
                  ref={el => videoRefs.current[index] = el}
                  src={short.video}
                  playsInline
                  loop
                  poster={short.thumbnail}
                  onLoadedData={() => handleVideoLoaded(index)}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'unset',
                    backgroundColor: '#000'
                  }}
                />
                {pausedVideos[index] && (
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(128, 128, 128, 0.5)',
                    borderRadius: '50%',
                    width: 60,
                    height: 60,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <PlayArrow sx={{ color: '#FFD700', fontSize: 40 }} />
                  </Box>
                )}
              </Box>

              {/* Like and Share buttons */}
              <Box sx={{
                position: 'absolute',
                right: 16,
                bottom: isMobile ? 80 : 40,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                zIndex: 2
              }}>
                <AnimatedIconButton
                  onClick={(e) => handleLike(e, index)}
                  disabled={liking}
                  sx={{
                    color: 'white',
                    '& .MuiSvgIcon-root': {
                      fontSize: 30,
                      fill: short.like === 1 ? '#ff0000' : 'transparent',
                      stroke: short.like === 1 ? 'none' : 'currentColor',
                      strokeWidth: short.like === 1 ? 0 : 1,
                    }
                  }}
                >
                  <Favorite />
                </AnimatedIconButton>
                <IconButton
                  onClick={handleShare}
                  sx={{
                    color: 'white',
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }
                  }}
                >
                  <Share sx={{ fontSize: 28 }} />
                </IconButton>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>

      <Dialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        TransitionProps={{
          timeout: 500,
        }}
        PaperProps={{
          sx: {
            position: 'fixed',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: '450px',
            m: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            backgroundColor: '#1a1a1a',
            maxHeight: '70vh',
          }
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          '& .MuiDialog-container': {
            alignItems: 'flex-end'
          }
        }}
      >
        <Box sx={{
          width: '100%',
          height: 4,
          display: 'flex',
          justifyContent: 'center',
          pt: 1,
          pb: 2
        }}>
          <Box sx={{
            width: 40,
            height: 4,
            backgroundColor: 'grey.500',
            borderRadius: 2
          }} />
        </Box>
        <DialogContent>
          <Typography variant="h6" sx={{ color: 'white', mb: 2, pl: 1 }}>
            Share to
          </Typography>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid item xs={3}>
              <ShareButton
                icon={ContentCopy}
                label="Copy Link"
                onClick={handleCopyLink}
              />
            </Grid>
            <Grid item xs={3}>
              <ShareButton
                icon={WhatsApp}
                label="WhatsApp"
                onClick={handleWhatsAppShare}
                color="#25D366"
              />
            </Grid>
            <Grid item xs={3}>
              <ShareButton
                icon={Instagram}
                label="Direct"
                onClick={() => handleInstagramShare('direct')}
                color="#E4405F"
              />
            </Grid>
            <Grid item xs={3}>
              <ShareButton
                icon={X}
                label="X"
                onClick={handleXShare}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {loadingMore && (
        <Box sx={{
          position: 'absolute',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1000
        }}>
          <CircularProgress size={24} sx={{ color: '#FFD700' }} />
        </Box>
      )}
    </Box>
  );
};

export default Shorts;