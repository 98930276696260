import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Container,
  CircularProgress,
  IconButton,
  styled
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { submitPuzzleClaim } from "../apiCalls/eastereggs/api_v1_eastereggs";

const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3),
  backgroundColor: '#1A1A1A',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFE8BC',
  borderRadius: 16,
  padding: theme.spacing(3),
  border: 'black 2px solid',
  overflow: 'visible',
  position: 'relative',
  width: '100%',
  maxWidth: '500px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  }
}));

const HeaderButton = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFA800',
  color: '#252525',
  padding: '8px 16px',
  borderRadius: 24,
  fontWeight: 600,
  border: 'black 2px solid',
  position: 'absolute',
  top: '-20px',
  left: '50%',
  transform: 'translateX(-50%)',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
    padding: '6px 12px',
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FAAE1C',
  color: '#252525',
  borderRadius: 16,
  padding: '10px 20px',
  fontWeight: 600,
  border: '1px solid black',
  marginTop: theme.spacing(3),
  '&:hover': {
    backgroundColor: '#e69e1a',
  },
  '&:disabled': {
    backgroundColor: '#ccc',
    color: '#666',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    color: '#252525',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FAAE1C',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#252525',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#252525',
  },
}));

const SubmitRedeem = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    item: '',
    uuid: '',
    puzzleId: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [itemName, setItemName] = useState('');

  useEffect(() => {
    document.title = 'Submit Redeem | Gamez Academy';
  })

  useEffect(() => {
    document.body.style.backgroundColor = "#1A1A1A";
    
    try {
      const refreshToken = sessionStorage.getItem('refreshToken');
      let phoneNumber = '';
      
      if (refreshToken) {
        try {
            phoneNumber = refreshToken; 
        } catch (e) {
            console.error('Error parsing refresh token:', e);
        }
      }
      
      setFormData(prevState => ({
        ...prevState,
        phone: phoneNumber
      }));
    } catch (error) {
      console.error('Error retrieving phone number from sessionStorage:', error);
    }
    
    if (!location.state) {
      navigate('/puzzle', { replace: true });
      return;
    }

    const { item, uuid, itemName, puzzleId } = location.state;

    if (!item || !uuid || !puzzleId) {
      navigate('/puzzle', { replace: true });
      return;
    }

    setFormData(prevState => ({
      ...prevState,
      item: item,
      uuid: uuid,
      puzzleId: puzzleId || ''
    }));
    
    setItemName(itemName || '');
  }, [location, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const submissionData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        item: formData.item,
        uuid: formData.uuid
      };

      if (formData.puzzleId) {
        submissionData.puzzleId = formData.puzzleId;
      }

      const response = await submitPuzzleClaim(submissionData);
      if (response.status === 200 && response.data.resultCode?.message === "ok") {
        navigate('/thankyou');
      } else {
        setError(response.data.resultCode?.message || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      setError(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate('/puzzle');
  };

  return (
    <StyledContainer>
      <StyledPaper elevation={3}>
        <HeaderButton>Claim Your Reward</HeaderButton>
        <IconButton
          onClick={handleCancel}
          sx={{
            '&:hover': { backgroundColor: '#dbc69f' },
            position: 'absolute',
            top: '-20px',
            right: '-10px',
            border: '2px solid black',
            color: '#252525',
            backgroundColor: '#FFE8BC',
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ mt: 3, mb: 3, textAlign: 'center' }}>
          <Typography variant="h5" sx={{ color: '#252525', fontWeight: 'bold' }}>
            Congratulations!
          </Typography>
          <Typography variant="body1" sx={{ color: '#252525', mt: 1 }}>
            You're about to claim your reward
          </Typography>
          <Typography variant="h6" sx={{ color: '#252525', fontWeight: 'bold', mt: 1 }}>
            {itemName}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <StyledTextField
            fullWidth
            label="Full Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            variant="outlined"
            required
            inputProps={{ minLength: 3, maxLength: 50 }}
          />
          <StyledTextField
            fullWidth
            label="Email Address"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            variant="outlined"
            required
            inputProps={{ minLength: 5, maxLength: 50 }}
          />
          <StyledTextField
            fullWidth
            label="Phone Number"
            name="phone"
            type="tel"
            value={formData.phone}
            onChange={handleChange}
            variant="outlined"
            required
            inputProps={{ minLength: 10, maxLength: 15 }}
            InputProps={{
              readOnly: !!formData.phone,
            }}
          />

          {error && (
            <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
              {error}
            </Typography>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <StyledButton 
              type="submit" 
              variant="contained" 
              disabled={isSubmitting}
              fullWidth
            >
              {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Submit Claim'}
            </StyledButton>
          </Box>
        </form>
      </StyledPaper>
    </StyledContainer>
  );
};

export default SubmitRedeem;