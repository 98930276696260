import axios from 'axios';
import { useContext } from 'react';
import { getToken } from './jwt/api_v1_jt';
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

export const loginAxiosClient = axios.create({
  baseURL: process.env.REACT_APP_LOGIN_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

// Axios interceptor with refresh token logic
axiosClient.interceptors.response.use(
  function (response) {
    // Simply return the response if no error
    return response;
  },
  async function (error) {
    let res = error.response;

    if (res.status === 401) {
      
      const msisdn = sessionStorage.getItem('refreshToken');
      console.log("MSISDN: " + msisdn)
      if (msisdn) {
        try {
          
          const response = await getToken({"msisdn": msisdn})
          const token = response.headers.access_token

          if (token) {
            sessionStorage.setItem('accessToken', token);
            const originalRequest = error.config;
            originalRequest.headers['Authorization'] = `Bearer ${token}`;

            return axiosClient(originalRequest);
          }
        } catch (err) {
          console.error("Failed to refresh token:", err);
          // Optionally handle when token refresh fails, like logging out or redirecting to an error page
        }
      }
    }

    // Return the error if it's not a 401 or after the refresh fails
    console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

export default axiosClient;
