import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  styled,
  IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

const StoryImage = styled('img')({
  width: '100%',
  maxWidth: '450px',
  height: '100%',
  objectFit: 'fill'
});

const ProgressContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  padding: '10px',
  gap: '4px',
  zIndex: 10
});

const ProgressBar = styled(Box)(({ active }) => ({
  flex: 1,
  height: '4px',
  backgroundColor: active ? '#fff' : 'rgba(255, 255, 255, 0.3)',
  borderRadius: '2px',
  transition: 'all 0.3s ease'
}));

const CloseButton = styled(IconButton)({
  color: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  padding: '8px',
});

const Story = () => {
  const location = useLocation();
  const { stories = [], currIndex = 0 } = location.state || {};
  const [currentIndex, setCurrentIndex] = useState(currIndex);

  const handleClick = (e) => {
    const { clientX, target } = e;
    const { width } = target.getBoundingClientRect();
    const isLeftHalf = clientX < width / 2;
    
    if (isLeftHalf) {
      setCurrentIndex((prev) => prev > 0 ? prev - 1 : prev);
    } else {
      setCurrentIndex((prev) => prev < stories.length - 1 ? prev + 1 : prev);
    }
  };

  useEffect(() => {
    document.title = 'Gamez Tips | Gamez Academy';
  })

  if (stories.length === 0) return null;

  return (
    <Dialog open={true} fullScreen>
      <DialogContent
        sx={{
          p: 0,
          position: 'relative',
          bgcolor: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ProgressContainer>
          {stories.map((_, index) => (
            <ProgressBar
              key={index}
              active={index <= currentIndex}
            />
          ))}
        </ProgressContainer>

        <CloseButton
          onClick={() => window.history.back()}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            zIndex: 10,
          }}
        >
          <CloseIcon fontSize="medium" />
        </CloseButton>

        <StoryImage
          src={stories[currentIndex].thumbnail}
          onClick={handleClick}
          alt={`Story ${currentIndex + 1}`}
        />
      </DialogContent>
    </Dialog>
  );
};

export default Story;