import React, { createContext, useState, useEffect } from 'react';
import { getToken, storeToken } from './api_v1_jt';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        accessToken: sessionStorage.getItem('accessToken'),
        refreshToken: sessionStorage.getItem('refreshToken'),
    });

    useEffect(() => {
        const updateAuthFromStorage = () => {
            const accessToken = sessionStorage.getItem('accessToken');
            const refreshToken = sessionStorage.getItem('refreshToken');
            setAuth({ accessToken, refreshToken });
        };

        const handleStorageChange = (event) => {
            if (event.key === 'accessToken' || event.key === 'refreshToken') {
                updateAuthFromStorage();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        updateAuthFromStorage();

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const updateAuth = (accessToken, refreshToken) => {
        if (accessToken) {
            sessionStorage.setItem('accessToken', accessToken);
        } else {
            sessionStorage.removeItem('accessToken');
        }

        if (refreshToken) {
            sessionStorage.setItem('refreshToken', refreshToken);
        } else {
            sessionStorage.removeItem('refreshToken');
        }

        setAuth({ accessToken, refreshToken });

        window.dispatchEvent(new Event('storage'));
    };

    const fetchNewRefreshToken = async (msisdn) => {
        try {
            const response = await getToken({ "msisdn": msisdn });
            console.log(response)
            const accessToken = response.headers.access_token;
            storeToken(accessToken);
            updateAuth(accessToken, msisdn);
            return { accessToken, msisdn };
        } catch (error) {
            console.error('Error fetching refresh token:', error);
            return null;
        }
    };

    return (
        <AuthContext.Provider value={{ auth, updateAuth, fetchNewRefreshToken }}>
            {children}
        </AuthContext.Provider>
    );
};
