import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    Button,
    Paper,
    styled
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const StyledContainer = styled(Container)(({ theme }) => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
    backgroundColor: '#1A1A1A',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: '#FFE8BC',
    borderRadius: 16,
    padding: theme.spacing(4),
    border: 'black 2px solid',
    width: '100%',
    maxWidth: '500px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#FAAE1C',
    color: 'black',
    borderRadius: 16,
    padding: '10px 20px',
    fontWeight: 600,
    border: '1px solid black',
    marginTop: theme.spacing(3),
    '&:hover': {
        backgroundColor: '#e69e1a',
    },
}));

const ThankYou = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Thankyou | Gamez Academy';
        document.body.style.backgroundColor = "#1A1A1A";
    }, []);

    const handleBackToPuzzles = () => {
        navigate('/puzzle');
    };

    return (
        <StyledContainer>
            <StyledPaper elevation={3}>
                <CheckCircleOutlineIcon sx={{ fontSize: 80, color: '#1FAF38', mb: 2 }} />

                <Typography variant="h4" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
                    Thank You!
                </Typography>

                <Typography variant="body1" sx={{ color: '#000', mb: 3 }}>
                    We have received your redemption request, and our team will contact you within 24 hours. <br /> Please note that our business hours are Monday to Friday, from 9:00 AM to 10:00 PM. Any redemption requests submitted after 10:00 PM on Friday will be processed on the following Monday. <br />
                    Happy Gaming!
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <StyledButton
                        variant="contained"
                        onClick={handleBackToPuzzles}
                    >
                        Back to Puzzles
                    </StyledButton>
                </Box>
            </StyledPaper>
        </StyledContainer>
    );
};

export default ThankYou;