import React, { useEffect, useState, useRef } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import { Box, CircularProgress, Typography, Paper } from '@mui/material';

const GAFps = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
  const [showRotateMessage, setShowRotateMessage] = useState(false);
  const [contextError, setContextError] = useState(false);
  const gameContainerRef = useRef(null);
  
  // Add error handling to the context
  const { 
    unityProvider, 
    isLoaded, 
    sendMessage, 
    requestFullscreen, 
    addEventListener,
    removeEventListener,
    unload, // Added to properly cleanup
    loadingProgression
  } = useUnityContext({
    loaderUrl: "ga-fps/Build/ga-fps.loader.js",
    dataUrl: "ga-fps/Build/ga-fps.data.unityweb",
    frameworkUrl: "ga-fps/Build/ga-fps.framework.js.unityweb",
    codeUrl: "ga-fps/Build/ga-fps.wasm.unityweb",
    webglContextAttributes: {
      powerPreference: "high-performance",
      preserveDrawingBuffer: true,
      failIfMajorPerformanceCaveat: false,
      // Force using WebGL 1.0 for better compatibility
      majorVersion: 1, 
      minorVersion: 0
    }
  });

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Handle errors
  useEffect(() => {
    // Error handling
    const handleError = (message) => {
      console.error("Unity WebGL Error:", message);
      setContextError(true);
    };

    // Add error listener
    addEventListener("error", handleError);
    
    // Add visibility change detection for context recovery
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && contextError) {
        // Try to reload the component if it became visible again after an error
        window.location.reload();
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup
    return () => {
      removeEventListener("error", handleError);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      // Properly unload Unity when component unmounts
      unload();
    };
  }, [addEventListener, removeEventListener, unload, contextError]);

  // Handle authentication token passing to Unity
  useEffect(() => {
    if (isLoaded) {
      const accessToken = sessionStorage.getItem('accessToken');
      if (accessToken) {
        // Delay token set slightly to ensure Unity is ready
        setTimeout(() => {
          try {
            sendMessage("GameManager", "SetAuthToken", accessToken);
          } catch (e) {
            console.error("Error sending message to Unity:", e);
          }
        }, 500);
      }
    }
  }, [isLoaded, sendMessage]);

  useEffect(() => {
    document.title = 'AimTrain | Gamez Academy';
  })

  // Handle orientation changes
  useEffect(() => {
    const checkOrientation = () => {
      const isLandscapeNow = window.innerWidth > window.innerHeight;
      setIsLandscape(isLandscapeNow);
      setShowRotateMessage(isMobile && !isLandscapeNow);
    };

    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    window.addEventListener('orientationchange', checkOrientation);

    return () => {
      window.removeEventListener('resize', checkOrientation);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, [isMobile]);

  // Handle fullscreen and orientation locking
  useEffect(() => {
    let fullscreenAttemptTimeout;

    const lockOrientation = async () => {
      try {
        if (window.screen && window.screen.orientation) {
          await window.screen.orientation.lock('landscape');
          console.log('Orientation locked to landscape');
        }
      } catch (error) {
        console.log('Orientation lock failed:', error);
      }
    };

    const enterFullscreen = async () => {
      try {
        // Only attempt Unity fullscreen after it's properly loaded
        if (isLoaded && loadingProgression === 1) {
          await requestFullscreen(true);
          setIsFullscreen(true);
        } 
        // Fallback to container fullscreen
        else if (gameContainerRef.current && gameContainerRef.current.requestFullscreen) {
          await gameContainerRef.current.requestFullscreen();
          setIsFullscreen(true);
        }
      } catch (error) {
        console.log('Fullscreen request failed:', error);
      }
    };

    const hideAddressBar = () => {
      if ('standalone' in window.navigator && !window.navigator.standalone) {
        setTimeout(() => {
          window.scrollTo(0, 1);
        }, 100);
      }
    };

    const handleOrientationAndUI = async () => {
      // Clear any previous attempts
      clearTimeout(fullscreenAttemptTimeout);
      
      if (isLoaded && isMobile) {
        // For iOS, delay orientation locking to prevent context issues
        fullscreenAttemptTimeout = setTimeout(async () => {
          await lockOrientation();
          hideAddressBar();
          await enterFullscreen();
        }, 1000);
      }
    };

    // Set up the game environment when loaded
    if (isLoaded) {
      handleOrientationAndUI();
    }

    // Re-apply fullscreen on user interaction (needed for iOS)
    const handleUserInteraction = () => {
      if (isLoaded && isMobile && !isFullscreen) {
        enterFullscreen();
        hideAddressBar();
      }
    };

    document.addEventListener('touchend', handleUserInteraction, false);
    document.addEventListener('click', handleUserInteraction, false);

    return () => {
      clearTimeout(fullscreenAttemptTimeout);
      document.removeEventListener('touchend', handleUserInteraction);
      document.removeEventListener('click', handleUserInteraction);
    };
  }, [isLoaded, requestFullscreen, isMobile, isFullscreen, loadingProgression]);

  // Add reload button functionality for errors
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Box
      ref={gameContainerRef}
      sx={{
        width: '100dvw',
        height: '100dvh',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'black',
        touchAction: 'none',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        userSelect: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        // Force landscape styling for portrait devices
        ...(isMobile && !isLandscape && {
          transform: 'rotate(-90deg)',
          transformOrigin: 'left top',
          width: '100vh',
          height: '100vw',
          position: 'absolute',
          top: '100%',
          left: 0
        })
      }}
    >
      {!contextError && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            visibility: isLoaded && (!isMobile || isLandscape || !showRotateMessage) ? 'visible' : 'hidden',
            position: 'relative',
            '& canvas': {
              width: '100% !important',
              height: '100% !important',
              outline: 'none'
            }
          }}
        >
          <Unity 
            unityProvider={unityProvider} 
            style={{ 
              width: '100%', 
              height: '100%'
            }}
            devicePixelRatio={window.devicePixelRatio}
          />
        </Box>
      )}
      
      {contextError && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#000',
            zIndex: 1001,
            color: 'white',
            textAlign: 'center',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Typography variant="h6" color="error">
            Game Error
          </Typography>
          <Typography variant="body1">
            There was an error loading the game. This may be due to WebGL compatibility issues.
          </Typography>
          <Paper
            sx={{
              p: 2,
              mt: 2,
              cursor: 'pointer',
              backgroundColor: '#444',
              color: 'white'
            }}
            onClick={handleReload}
          >
            <Typography variant="button">
              Reload Game
            </Typography>
          </Paper>
        </Box>
      )}
      
      {showRotateMessage && !contextError && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#000',
            zIndex: 1001,
            color: 'white',
            textAlign: 'center',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Box
            sx={{
              animation: 'rotate 1.5s ease-in-out infinite',
              '@keyframes rotate': {
                '0%': { transform: 'rotate(0deg)' },
                '50%': { transform: 'rotate(-90deg)' },
                '100%': { transform: 'rotate(0deg)' }
              },
              width: 64,
              height: 64
            }}
          >
            <svg viewBox="0 0 100 100" width="100%" height="100%">
              <rect x="20" y="40" width="60" height="30" rx="5" stroke="white" strokeWidth="4" fill="none"/>
              <circle cx="50" cy="55" r="5" stroke="white" strokeWidth="2" fill="none"/>
            </svg>
          </Box>
          <Typography variant="h6">
            Please rotate your device to landscape mode
          </Typography>
        </Box>
      )}
      
      {!isLoaded && !contextError && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#000',
            zIndex: 1000
          }}
        >
          <Paper 
            elevation={3}
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <CircularProgress 
              size={40} 
              variant="determinate" 
              value={loadingProgression * 100}
            />
            <Typography variant="h6" color="#252525">
              Loading game... {Math.round(loadingProgression * 100)}%
            </Typography>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default GAFps;