import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  Tabs,
  Tab,
  styled,
  Tooltip
} from '@mui/material';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import GamezTips from '../components/GamezTips';
import GamezBites from '../components/GamezBites';
import GamezGuides from '../components/GamezGuides';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { getAllGames } from '../apiCalls/games/api_v1_games';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import CallofDuty from '../static/img/home/CallOfDuty.png'
import Fortnite from '../static/img/home/Fortnite.png'
import Valorant from '../static/img/home/Valorant.png'
import Freefire from '../static/img/home/Freefire.png'
import Minecraft from '../static/img/home/Minecraft.png'
import Pubg from '../static/img/home/PUBG.png'
import TestSkillsDialog from '../components/TestSkillsDialog';

SwiperCore.use([Autoplay]);

const HeroCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '230px',
  background: '#1D1D1D',
  borderTopRightRadius: '800px',
  borderTopLeftRadius: '188px',
  overflow: 'hidden',
  '& .hero-content': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1
  },
  '& .background-shape': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    clipPath: 'path("M 0 0 H 100% V 30% Q 85% 50% 75% 100% H 0 V 0")',
    background: 'linear-gradient(135deg, #FAAE1C 0%, #FFB938 100%)',
    zIndex: 0
  }
}));

const ContentWrapper = styled(Box)({
  position: 'relative',
  padding: '1em',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: 2
});

const StyledTitle = styled(Typography)({
  fontFamily: "'Orbitron-SemiBold', sans-serif", 
  fontSize: '2.5rem',
  fontWeight: 'bold',
  color: '#000000',
  marginBottom: '1rem'
});

const CharacterImage = styled('div')({
  position: 'absolute',
  right: '-2%',
  top: '80%',
  transform: 'translateY(-50%)',
  height: '120%',
  width: '50%',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  zIndex: 2
});

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  '& .MuiTabs-indicator': {
    backgroundColor: '#FAAE1C',
  },
  '& .MuiTab-root': {
    fontFamily: "'Poppins-Regular', sans-serif",
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
      color: '#FAAE1C',
    },
    '&.Mui-disabled': {
      opacity: 0.5,
      color: theme.palette.text.disabled,
    }
  }
}));

function Home() {
  const [tabValue, setTabValue] = useState(0);
  const [games, setGames] = useState([]);
  const [userSkillLevel, setUserSkillLevel] = useState('beginner');
  const [isSkillDialogOpen, setIsSkillDialogOpen] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width:900px)');

  const getGameImage = (gameName) => {
    switch (gameName) {
      case 'Fortnite':
        return Fortnite;
      case 'PUBG':
        return Pubg;
      case 'Call of Duty':
        return CallofDuty;
      case 'Minecraft':
        return Minecraft;
      case 'Valorant':
        return Valorant;
      case 'Freefire':
        return Freefire;
      default:
        return null;
    }
  };

  useEffect(() => {
    document.title = "Home | Gamez Academy";
    const skillLevel = sessionStorage.getItem('skillLevel') || 'beginner';
    setUserSkillLevel(skillLevel);
    if (skillLevel === 'beginner') setTabValue(0);
    else if (skillLevel === 'rookie') setTabValue(1); 
    else if (skillLevel === 'pro') setTabValue(2);

    const fetchGames = async () => {
      try {
        const response = await getAllGames();
        if (response.data.success) {
          setGames(response.data.data.games);
        }
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };

    fetchGames();
  }, []);

  useEffect(() => {
    if (swiperInstance && games.length > 1) {
      swiperInstance.autoplay.start();
    }
  }, [swiperInstance, games]);

  const canAccessTab = (tabIndex) => {
    const levelAccess = {
      'beginner': [0],
      'rookie': [0, 1],
      'pro': [0, 1, 2]
    };
    return levelAccess[userSkillLevel]?.includes(tabIndex) || false;
  };

  const handleTabChange = (event, newValue) => {
    console.log("TAB VAL: " + newValue);
    if (canAccessTab(newValue)) {
      setTabValue(newValue);
    } else {
      setIsSkillDialogOpen(true);
    }
  };

  const handleGameClick = (uuid, gameName) => {
    navigate(`/game?uuid=${uuid}&game=${gameName}`);
  };

  const getTabProps = (index) => {
    const isAccessible = canAccessTab(index);
    return {
      disabled: false,
      label: (
        <Tooltip
          title={!isAccessible ? `Unlock this content by reaching ${index === 1 ? 'Rookie' : 'Pro'} level` : ''}
          arrow
          placement="top"
        >
          <span>{index === 0 ? 'Beginner' : index === 1 ? 'Rookie' : 'Pro'}</span>
        </Tooltip>
      )
    };
  };

  const getContentForTab = (tabIndex) => {
    const tabToSkillLevel = {
      0: 'beginner',
      1: 'rookie',
      2: 'pro'
    };
    
    return tabToSkillLevel[tabIndex] || 'beginner';
  };

  return (
    <Box
      sx={{
        maxWidth: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
        fontFamily: "'Poppins-Regular', sans-serif", // Set Poppins as default font for the whole component
      }}
    >
      <Box sx={{ position: 'relative', mb: 2 }}>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={games.length > 1}
          modules={[Autoplay]}
          onSwiper={setSwiperInstance}
          speed={800}
        >
          {games.map((game) => (
            <SwiperSlide key={game.uuid} onClick={() => handleGameClick(game.uuid, game.name)}>
              <HeroCard elevation={0}>
                <div className="background-shape" />
                <ContentWrapper>
                  <StyledTitle>{game.name}</StyledTitle>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "'Poppins-Regular', sans-serif", // Set Poppins for subtitle
                      color: '#000000',
                      fontSize: '1.1rem',
                      maxWidth: '70%',
                    }}
                  >
                    Find the best guide on your favorite games
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(0,0,0)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <ArrowForwardTwoToneIcon color='primary' />
                    </Box>
                  </Box>
                </ContentWrapper>
                <CharacterImage
                  sx={{
                    backgroundImage: `url(${getGameImage(game.name)})`
                  }}
                />
              </HeroCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <StyledTabs
        value={tabValue}
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label="skill level tabs"
      >
        <Tab {...getTabProps(0)} />
        <Tab {...getTabProps(1)} />
        <Tab {...getTabProps(2)} />
      </StyledTabs>

      {!isDesktop && <GamezTips skillLevel={getContentForTab(tabValue)} />}
      <GamezBites skillLevel={getContentForTab(tabValue)} />
      <GamezGuides skillLevel={getContentForTab(tabValue)} />

      <TestSkillsDialog open={isSkillDialogOpen}
        onClose={() => setIsSkillDialogOpen(false)} />
    </Box>
  );
}

export default Home;