import React, { useEffect, useState, useRef } from 'react';
import { getProfile, editProfile } from '../apiCalls/user_profile/api_v1_profile';
import { storeToken } from '../apiCalls/jwt/api_v1_jt';
import {
    Box,
    Typography,
    TextField,
    Button,
    Avatar,
    Container,
    Grid,
    useTheme,
    useMediaQuery,
    IconButton,
    Menu,
    MenuItem,
    Select,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isEditMode, setIsEditMode] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const submitButtonRef = useRef(null);
    
    const [formData, setFormData] = useState({
        username: '',
        firstname: '',
        lastname: '',
        bio: '',
        phone: '',
        gender: '',
        email: '',
        dateofbirth: '',
        joinDate: '',
        country: '',
        city: '',
        profileImage: '',
        social_facebook: '',
        social_twitter: '',
        social_skype: '',
        social_youtube: ''
    });

    useEffect(() => {
        document.title = 'Profile | Gamez Academy';
        const fetchUserInfo = async () => {
            try {
                const cachedProfile = JSON.parse(sessionStorage.getItem("profileData"));
                if (cachedProfile) {
                    updateFormDataFromResponse(cachedProfile);
                    
                    setTimeout(async () => {
                        const response = await getProfile();
                        if (response.status === 200 && response.data.success) {
                            sessionStorage.setItem("profileData", JSON.stringify(response.data.data));
                            updateFormDataFromResponse(response.data.data);
                        }
                    }, 3000);
                } else {
                    const response = await getProfile();
                    if (response.status === 200 && response.data.success) {
                        sessionStorage.setItem("profileData", JSON.stringify(response.data.data));
                        updateFormDataFromResponse(response.data.data);
                    }
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };
        fetchUserInfo();
    }, []);

    const updateFormDataFromResponse = (data) => {
        setFormData({
            username: data.username || '',
            firstname: data.name ? data.name.split(' ')[0] : '',
            lastname: data.name ? data.name.split(' ')[1] : '',
            city: data.city || '',
            country: data.country || '',
            bio: data.bio || '',
            gender: data.gender || '',
            email: data.email || '',
            dateofbirth: data.dateOfBirth || '',
            phone: data.phone ? data.phone.toString() : '',
            joinDate: data.joinDate || '',
            profileImage: data.profileImage || '',
            social_facebook: data.social_facebook || '',
            social_twitter: data.social_twitter || '',
            social_skype: data.social_skype || '',
            social_youtube: data.social_youtube || ''
        });
    };

    const handleChange = (field) => (event) => {
        setFormData({
            ...formData,
            [field]: event.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (submitButtonRef.current) {
            submitButtonRef.current.disabled = true;
        }

        const {
            credits,
            error,
            permission,
            phone,
            success,
            name,
            joinDate,
            message,
            profileImage,
            dateOfBirth,
            ...filteredFormData
        } = formData;

        try {
            const response = await editProfile(filteredFormData);
            if (response.headers['access_token']) {
                storeToken(response.headers['access_token']);
            }
            sessionStorage.removeItem('profileData');
            setIsEditMode(false);
            if (submitButtonRef.current) {
                submitButtonRef.current.disabled = false;
            }
            navigate("/profile");
        } catch (error) {
            console.error('Error updating profile:', error);
            if (submitButtonRef.current) {
                submitButtonRef.current.disabled = false;
            }
        }
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditClick = () => {
        setIsEditMode(true);
        handleMenuClose();
    };

    const handleCancel = () => {
        setIsEditMode(false);
    };

    const handleUnsubscribe = () => {
        navigate("/unsubscribe");
        handleMenuClose();
    };

    const handleLogout = () => {
        handleMenuClose();
        sessionStorage.clear();
        window.location.href = process.env.REACT_APP_LOGIN_URL;
    }

    const textFieldStyles = {
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#252525',
            borderRadius: '10px',
            '& fieldset': {
                border: 'none',
            },
        },
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 2,
                    p: 3,
                    position: 'relative'
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h6" component="h1">
                        Profile
                    </Typography>
                    <IconButton onClick={handleMenuOpen}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleEditClick}>Edit Profile</MenuItem>
                    </Menu>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                    <Avatar
                        src={formData.profileImage}
                        sx={{
                            width: 64,
                            height: 64,
                            mr: 2,
                            bgcolor: theme.palette.primary.main
                        }}
                    />
                    <Typography variant="h6" component="span">
                        {formData.username}
                    </Typography>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    First Name
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={formData.firstname}
                                    onChange={handleChange('firstname')}
                                    disabled={!isEditMode}
                                    sx={textFieldStyles}
                                />
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    Last Name
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={formData.lastname}
                                    onChange={handleChange('lastname')}
                                    disabled={!isEditMode}
                                    sx={textFieldStyles}
                                />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    Bio
                                </Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={formData.bio}
                                    onChange={handleChange('bio')}
                                    disabled={!isEditMode}
                                    sx={textFieldStyles}
                                />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    Gender
                                </Typography>
                                <Select
                                    fullWidth
                                    value={formData.gender}
                                    onChange={handleChange('gender')}
                                    disabled={!isEditMode}
                                    sx={{
                                        ...textFieldStyles,
                                        color: "white",
                                        borderColor: "white",
                                        backgroundColor: "#252525",
                                        borderRadius: '8px'
                                    }}
                                >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                </Select>
                            </Box>

                            <Box>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    Email
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={formData.email}
                                    onChange={handleChange('email')}
                                    disabled={!isEditMode}
                                    sx={textFieldStyles}
                                />
                            </Box>

                            <Box>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    Date of Birth
                                </Typography>
                                <TextField
                                    fullWidth
                                    type="date"
                                    value={formData.dateofbirth}
                                    onChange={handleChange('dateofbirth')}
                                    disabled={!isEditMode}
                                    sx={{
                                        ...textFieldStyles,
                                        backgroundColor: "#252525",
                                        color: "white",
                                        padding: "5px",
                                        borderRadius: '8px'
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    City
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={formData.city}
                                    onChange={handleChange('city')}
                                    disabled={!isEditMode}
                                    sx={textFieldStyles}
                                />
                            </Box>

                            <Box>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    Country
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={formData.country}
                                    onChange={handleChange('country')}
                                    disabled={!isEditMode}
                                    sx={textFieldStyles}
                                />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mb: 2 }}>Social Links</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    Facebook
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={formData.social_facebook}
                                    onChange={handleChange('social_facebook')}
                                    disabled={!isEditMode}
                                    sx={textFieldStyles}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    Twitter
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={formData.social_twitter}
                                    onChange={handleChange('social_twitter')}
                                    disabled={!isEditMode}
                                    sx={textFieldStyles}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    Skype
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={formData.social_skype}
                                    onChange={handleChange('social_skype')}
                                    disabled={!isEditMode}
                                    sx={textFieldStyles}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                                    YouTube
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={formData.social_youtube}
                                    onChange={handleChange('social_youtube')}
                                    disabled={!isEditMode}
                                    sx={textFieldStyles}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {isEditMode && (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            mt: 4,
                            flexDirection: isMobile ? 'column' : 'row',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={handleCancel}
                            sx={{
                                flex: isMobile ? 1 : 'none',
                                minWidth: isMobile ? '100%' : 120,
                                borderRadius: '1em',
                                color: 'primary.main',
                                background: 'transparent'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            ref={submitButtonRef}
                            variant="contained"
                            type="submit"
                            sx={{
                                flex: isMobile ? 1 : 'none',
                                minWidth: isMobile ? '100%' : 120,
                                color: '#252525',
                                borderRadius: '1em'
                            }}
                        >
                            Save Changes
                        </Button>
                    </Box>
                )}
                
                {isEditMode && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, gap: 1 }}>
                        <Button
                            onClick={handleLogout}
                            sx={{
                                background: 'transparent',
                                color: 'text.secondary',
                                fontSize: '0.75rem',
                                padding: '2px 8px',
                                minWidth: 'auto',
                                '&:hover': {
                                    background: 'transparent',
                                    color: 'text.primary',
                                }
                            }}
                        >
                            Logout
                        </Button>
                        <Button
                            onClick={handleUnsubscribe}
                            sx={{
                                background: 'transparent',
                                color: 'text.secondary',
                                fontSize: '0.75rem',
                                padding: '2px 8px',
                                minWidth: 'auto',
                                '&:hover': {
                                    background: 'transparent',
                                    color: 'text.primary',
                                }
                            }}
                        >
                            Unsubscribe
                        </Button>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default Profile;