import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    Box,
    Typography,
    Button,
    styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BeginnerIcon from '../static/img/testSkills/Beginner.png';
import RookieIcon from '../static/img/testSkills/Rookie.png';
import ProIcon from '../static/img/testSkills/Pro.png';
import { useNavigate } from 'react-router-dom';
import { getUserInfo } from '../apiCalls/user_profile/api_v1_profile';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: '#FFE8BC',
        borderRadius: 16,
        padding: theme.spacing(2),
        minWidth: 300,
        minHeight: 380,
        border: 'black 2px solid',
        overflow: 'visible'
    }
}));

const HeaderButton = styled(Box)(({ theme }) => ({
    backgroundColor: '#FFA800',
    color: 'black',
    padding: '.7em 1em',
    borderRadius: 50,
    fontWeight: 600,
    border: 'black 2px solid',
    position: 'absolute',
    top: '-20px',
    left: '30%',
    maxWidth: 230
}));

const ProgressContainer = styled(Box)({
    position: 'relative',
    width: '100%',
    marginBottom: '16px',
});

const ProgressItem = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    backgroundColor: '#FFDA93',
    borderRadius: '8px',
    height: '30px',
    marginLeft: '24px',
    paddingLeft: '40px',
    paddingRight: '16px',
});

const ProgressBar = styled(Box)({
    flex: 1,
    height: '12px',
    backgroundColor: '#D3D3D3',
    borderRadius: '10px',
    overflow: 'hidden'
});

const ProgressFill = styled(Box)(({ color }) => ({
    height: '100%',
    backgroundColor: color || '#32CD32',
    borderRadius: '10px',
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
    transition: 'width 0.5s ease-in-out'
}));

const LevelIcon = styled(Box)({
    width: '48px',
    height: '48px',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
});

const LevelText = styled(Typography)({
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
    minWidth: '80px'
});

const SubmitButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#FAAE1C',
    color: 'black',
    borderRadius: 16,
    padding: '8px',
    fontSize: '18px',
    fontWeight: 'bold',
    width: '100%',
    '&:hover': {
        backgroundColor: '#FF8C00',
    },
    position: 'absolute',
    bottom: '20px',
    left: "15%",
    maxWidth: '70%',
    textTransform: 'none'
}));

const MessageText = styled(Typography)({
    fontSize: '18px',
    textAlign: 'center',
    color: 'black',
    marginTop: '32px',
    marginBottom: '30px',
    padding: '0 16px',
    lineHeight: 1.2
});

const TestSkillsDialog = ({ open, onClose }) => {
    const navigate = useNavigate();
    const [skillLevel, setSkillLevel] = useState('');
    const [lastPlayedLevel, setLastPlayedLevel] = useState(0);

    useEffect(() => {
        const storedSkillLevel = sessionStorage.getItem('skillLevel') || 'beginner';
        const storedLastLevel = parseInt(sessionStorage.getItem('last_played_level')) || 1;
        setSkillLevel(storedSkillLevel);
        setLastPlayedLevel(storedLastLevel);

        const fetchUserInfo = async () => {
            try {
                const response = await getUserInfo();
                // console.log("GOT DATA ON POPUP");
                if (response.status == 200) {
                    sessionStorage.setItem('skillLevel', response.data.skill_level.toLowerCase())
                    sessionStorage.setItem('last_played_level', response.data.latest_played_level)
                    // console.log(response.data)
                }
            } catch {

            }
        };
        fetchUserInfo();
    }, []);

    const calculateProgress = (level) => {
        if (level === 'beginner') {
            if (skillLevel === 'rookie' || skillLevel === 'pro') return 100;
            return Math.min(((lastPlayedLevel) / 10) * 100, 100);
        }

        if (level === 'rookie') {
            if (skillLevel === 'pro') return 100;
            if (skillLevel === 'beginner') return 0;
            return Math.min(((lastPlayedLevel - 10) / 10) * 100, 100);
        }

        if (level === 'pro') {
            if (skillLevel === 'beginner' || skillLevel === 'rookie') return 0;
            return Math.min(((lastPlayedLevel - 20) / 10) * 100, 100);
        }

        return 0;
    };

    const getMessage = () => {
        switch (skillLevel) {
            case 'beginner':
                return "Welcome! Let's start your journey to become a pro!";
            case 'rookie':
                return "Great progress! You're on your way to becoming a pro.";
            case 'pro':
                return "Almost there! Complete the remaining challenges to become an expert.";
            default:
                return "Welcome to the skill test!";
        }
    };

    const levels = [
        {
            name: 'Beginner',
            progress: calculateProgress('beginner'),
            icon: BeginnerIcon,
            color: calculateProgress('beginner') === 100 ? '#32CD32' : '#FFA500'
        },
        {
            name: 'Rookie',
            progress: calculateProgress('rookie'),
            icon: RookieIcon,
            color: calculateProgress('rookie') === 100 ? '#32CD32' : '#FFA500'
        },
        {
            name: 'Pro',
            progress: calculateProgress('pro'),
            icon: ProIcon,
            color: calculateProgress('pro') === 100 ? '#32CD32' : '#FFA500'
        }
    ];

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <Box>
                <HeaderButton>
                    Test your skill set
                </HeaderButton>
                <IconButton
                    onClick={onClose}
                    sx={{
                        '&:hover': { backgroundColor: '#dbc69f' },
                        position: 'absolute',
                        top: '-20px',
                        right: '-10px',
                        border: '2px solid black',
                        color: 'black',
                        backgroundColor: '#FFE8BC'
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <DialogContent sx={{ p: 0 }}>
                <MessageText>
                    {/* {getMessage()} */}
                    Unlock the Next Level! <br />
                    Play the game and clear all levels to unlock exciting new challenges. <br />
                    Keep going—your next adventure awaits!
                </MessageText>

                <Box sx={{ px: 2, mb: 10 }}>
                    {levels.map((level) => (
                        <ProgressContainer key={level.name}>
                            <LevelIcon>
                                <img src={level.icon} alt={level.name} />
                            </LevelIcon>
                            <ProgressItem>
                                <LevelText>
                                    {level.name}
                                </LevelText>
                                <ProgressBar>
                                    <ProgressFill
                                        sx={{ width: `${level.progress}%` }}
                                        color={level.color}
                                    />
                                </ProgressBar>
                            </ProgressItem>
                        </ProgressContainer>
                    ))}
                </Box>

                <SubmitButton
                    variant="contained"
                    onClick={() => { onClose(); navigate('/ga-fps-game') }}
                >
                    Play Now
                </SubmitButton>
            </DialogContent>
        </StyledDialog>
    );
};

export default TestSkillsDialog;