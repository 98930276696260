import React, { useState } from 'react';
import {
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material';
import {
  Home as HomeIcon,
  AccountCircle as ProfileIcon,
  LibraryBooks as TestIcon,
  Palette as ThemeIcon,
  CardGiftcardOutlined,
  Settings as SettingsIcon,
  Description as TermsIcon,
  Lock as PrivacyIcon
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import logoImg from '../static/img/header/logo.svg';
import GamezTips from '../components/GamezTips';
import TestSkillsDialog from '../components/TestSkillsDialog';

const LayoutDesktop = ({ children }) => {
  const location = useLocation();
  const theme = useTheme();
  const [openTestSkills, setOpenTestSkills] = useState(false);

  const skillLevel = sessionStorage.getItem('skillLevel') || 'beginner';
  
  const isFpsGamePath = location.pathname === '/ga-fps-game';

  const NavItem = ({ to, icon: Icon, label, onClick }) => (
    <ListItem
      onClick={onClick}
      component={to ? Link : 'div'}
      to={to}
      sx={{
        color: location.pathname === to ? '#FFD700' : 'text.primary',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      }}
    >
      <ListItemIcon>
        <Icon sx={{ color: location.pathname === to ? '#FFD700' : 'inherit' }} />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden', gap: 2 }}>
        <Paper
          elevation={3}
          sx={{
            width: 240,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: 2,
          }}
        >
          <img
            src={logoImg}
            alt="GamezAcademy"
            style={{ height: 'auto', width: '130px' }}
          />
          <List component="nav" sx={{ width: '100%' }}>
            <NavItem to="/" icon={HomeIcon} label="Home" />
            <NavItem to="/profile" icon={ProfileIcon} label="Profile" />
            <NavItem to={null}
              onClick={(e) => {
                e.preventDefault();
                setOpenTestSkills(true)
              }}
              icon={TestIcon}
              label="Test your Skills"
            />
            <NavItem
              to="/puzzle"
              icon={CardGiftcardOutlined}
              label="Win a Gift"
            />
            {/* <NavItem to="/appearance-theme" icon={ThemeIcon} label="Appearance Theme" /> */}

            <Divider sx={{ my: 8 }} />

            {/* <NavItem to="/settings" icon={SettingsIcon} label="Settings" /> */}
            <NavItem to="/terms" icon={TermsIcon} label="Terms of Service" />
            {/* <NavItem to="/privacy" icon={PrivacyIcon} label="Privacy Policy" /> */}
          </List>
        </Paper>
        <Box sx={{
          flex: 1,
          overflow: 'auto',
          backgroundColor: theme.palette.background.paper,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
        }}>
          {children}
        </Box>
        
        {!isFpsGamePath && (
          <Paper
            elevation={3}
            sx={{
              width: 240,
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: 2,
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 'none',
            }}
          >
            <GamezTips skillLevel={skillLevel} />
          </Paper>
        )}
      </Box>
      <TestSkillsDialog open={openTestSkills} onClose={() => { setOpenTestSkills(false) }} />
    </Box>
  );
};

export default LayoutDesktop;