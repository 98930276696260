import axiosClient, { loginAxiosClient } from "../axiosClient"

// export function storeToken(data) {
//     console.log("storeToken", data)
//     if ((data !== undefined && data !== null) || data !== "") {
//         console.log("storeToken 2", data)
//         sessionStorage.setItem('token', data);
//     }
//     return true;
// }

export function storeToken(data) {

    if (data !== null && data !== "") {

        sessionStorage.setItem('accessToken', data);
    }
    return true;
}

export function getToken(access_code) {
    return axiosClient.post('/jt/gen', JSON.stringify(access_code));
}

export function reLogin() {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    window.location.href = process.env.REACT_APP_LOGIN_URL;
}

export function verifyToken() {
    let bearerTokenHeader = sessionStorage.getItem('accessToken');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get('/jt/dt');
}

export function verifyStatus(msisdn) {
    // let bearerTokenHeader = sessionStorage.getItem('accessToken');
    // axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get('https://api.gamezacademy.pk/api/get_user_current_status/' + msisdn);
}


export function getEncMsiUnsub(data) {
    return axiosClient.post('https://unsubscribe.gamezacademy.pk/api/encrypt', JSON.stringify(data));
}

