import React, { useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { getAllStories } from '../apiCalls/stories/api_v1_stories';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';




const StoryCircle = styled(Box)(({ theme }) => ({
  width: 60,
  height: 60,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: -1,
    background: 'linear-gradient(45deg, #FAAE1C, #FFB938)',
    borderRadius: '50%'
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 1,
    background: theme.palette.background.default,
    borderRadius: '50%'
  }
}));

const StoryImage = styled(Box)({
  position: 'absolute',
  inset: 3,
  zIndex: 2,
  borderRadius: '50%',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
});

const GamezTips = ({skillLevel}) => {
  const [stories, setStories] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gameUuid = queryParams.get('uuid');
  const path = window.location.pathname;
  const isDesktop = useMediaQuery('(min-width:900px)');
  const navigate = useNavigate();

  const ScrollContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    overflowX: 'auto',
    gap: isDesktop ? theme.spacing(1) : theme.spacing(2),
    padding: theme.spacing(2),
    flexDirection: isDesktop ? 'column' : 'row',
    '&::-webkit-scrollbar': { display: 'none' },
    scrollbarWidth: 'none',
    position: 'relative',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: 32,
      pointerEvents: 'none',
      zIndex: 1
    },
  }));

  useEffect(() => {
    const fetchStories = async () => {
      try {
        // let skillLevel = localStorage.getItem("skillLevel");
        if (path == '/game' && (gameUuid != null || gameUuid != undefined || gameUuid != "")) {
          const response = await getAllStories({ "uuid": gameUuid, "skillLevel": skillLevel });
          if (response.status === 200) {
            setStories(response.data.data.stories);
          }
        }
        else {
          const response = await getAllStories({"skillLevel": skillLevel});
          if (response.status === 200) {
            setStories(response.data.data.stories);
          }
        }

      } catch (error) {
        console.error('Error fetching stories:', error);
      }
    };
    fetchStories();
  }, [skillLevel]);

  return (
    <Box sx={{ p: 2, pb: 0, pt: 0, mb: 0 }}>
      <Typography variant="h6" >
        Gamez Tips
      </Typography>

      <ScrollContainer>
        {stories.map((story, index) => (
          <Box key={story.uuid} onClick={() => {
            navigate('/tips', {
              state: { stories: stories, currIndex: index }
            });
          }} >
            <StoryCircle>
              <StoryImage>
                <img loading='lazy' src={story.thumbnail} alt="Story thumbnail" />
              </StoryImage>
            </StoryCircle>
          </Box>
        ))}
      </ScrollContainer>
    </Box>
  );
};

export default GamezTips;