import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#FAAE1C',
    },
    background: {
      default: '#1D1D1D',
      paper: '#1D1D1D',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          '&.Mui-selected': {
            color: '#FAAE1C',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#FAAE1C',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#E59D0F',
          },
        },
        outlined: {
          borderColor: '#FAAE1C',
          color: '#FAAE1C',
          '&:hover': {
            borderColor: '#E59D0F',
            color: '#E59D0F',
          },
        },
      },
    },
  },
});

export default theme;