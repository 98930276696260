import axiosClient from "../axiosClient"
export function getAllShorts(data) {
    let bearerTokenHeader = sessionStorage.getItem('accessToken');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    let path = window.location.pathname;
    if (path === "/" || path === "/home") {
        return axiosClient.post('/home/shorts/all', JSON.stringify(data));
    } else {
        return axiosClient.post('/shorts/all', JSON.stringify(data));
    }
}

export function getSingleShort(id) {
    let bearerTokenHeader = sessionStorage.getItem('accessToken');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get(`/shorts/view/${id}`);
}

export function likeCurrentShort(data) {
    let bearerTokenHeader = sessionStorage.getItem('accessToken');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post(`/short/like/`, JSON.stringify(data));
}