import VideoPlayer from "../components/VideoPlayer";
import { getSinglePodcast, podcastViewed } from "../apiCalls/podcasts/api_v1_podcasts";
import GamezGuides from "../components/GamezGuides";
import {React, useState, useEffect} from "react";
import {Box, CircularProgress, Typography} from "@mui/material";
import { useLocation } from "react-router-dom";

function Podcast () {
    const [podcast, setPodcast] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const podcastUuid = queryParams.get('uuid');
    const skillLevel = sessionStorage.getItem("skillLevel");

    useEffect(() => {
        document.title = 'Game Guidez | Gamez Academy';
    }, [])

    useEffect(() => {
        const fetchPodcast = async () => {
            console.log(podcastUuid);
            try {
                const response = await podcastViewed(podcastUuid);
                if (response.status === 200) {
                    console.log(response)
                    setPodcast(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching podcast:', error);
            }
        };
        fetchPodcast();
    }, [podcastUuid]);

    return (
        <Box
            sx={{
                p: 2,
                color: 'white',
            }}
        >
            {podcast ? (
                <VideoPlayer
                    videoUrl={podcast.video}
                />
            ) : (
                <CircularProgress />
            )}

            <GamezGuides skillLevel={skillLevel}/>
        </Box>
    );
}

export default Podcast;