import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Lock as LockIcon, Close as CloseIcon } from '@mui/icons-material';
import { getPuzzleList, submitPuzzleRedeem } from "../apiCalls/eastereggs/api_v1_eastereggs";
import EasterEggDialog from '../components/EasterEggDialog';
import RedemptionDialog from '../components/RedeemDialog';

const StyledListItem = styled(ListItem)(({ theme, completed }) => ({
  backgroundColor: completed ? "#1FAF38" : '#252525',
  marginBottom: theme.spacing(1),
  borderRadius: 16,
  '&:hover': {
    backgroundColor: completed ? theme.palette.success.dark : theme.palette.grey[800],
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const BlurredImage = styled('img')(({ isLocked }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  filter: isLocked ? 'blur(5px) brightness(50%)' : 'none',
  transition: 'filter 0.3s ease',
  borderRadius: isLocked ? '8px' : '0',
}));

const LockOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(0, 0, 0, 0.3)',
  borderRadius: '8px',
});

const PuzzlePieceBox = styled(Box)(({ isLocked }) => ({
  position: 'relative',
  aspectRatio: '1/1',
  cursor: !isLocked ? 'default' : 'pointer',
  padding: 0,
  margin: 0,
  display: 'block',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block',
  }
}));

const CompactDialogContent = styled(DialogContent)({
  padding: '16px',
  '& .MuiGrid-root': {
    maxWidth: '800px',
    margin: '0 auto',
  }
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#FFE8BC',
    borderRadius: 16,
    padding: theme.spacing(2),
    border: 'black 2px solid',
    overflow: 'visible',
    margin: '32px',
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
      maxWidth: '800px',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
      maxWidth: '1000px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%',
      maxWidth: '1200px',
    }
  }
}));

const HeaderButton = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFA800',
  color: 'black',
  padding: '8px 16px',
  borderRadius: 24,
  fontWeight: 600,
  border: 'black 2px solid',
  position: 'absolute',
  top: '-20px',
  left: '30%',
  maxWidth: 230
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FAAE1C',
  color: 'black',
  borderRadius: 16,
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#e69e1a',
  },
  fontWeight: 600,
  border: '1px solid black'
}));

const Puzzle = () => {
  const navigate = useNavigate();
  const [puzzles, setPuzzles] = useState([]);
  const [selectedPuzzle, setSelectedPuzzle] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openEasterEgg, setOpenEasterEgg] = useState(false);
  const [currentPuzzlePart, setCurrentPuzzlePart] = useState(null);
  const [openRedemption, setOpenRedemption] = useState(false);
  const [redeemLoading, setRedeemLoading] = useState(false);
  const [redeemError, setRedeemError] = useState('');

  const getGridColumns = (totalParts) => {
    if (totalParts <= 4) return 2;
    if (totalParts <= 6) return 3;
    if (totalParts <= 9) return 3;
    return 4;
  };

  useEffect(() => {
    document.title = 'Puzzles | Gamez Academy';
    const fetchPuzzles = async () => {
      try {
        const response = await getPuzzleList();
        if (response.status === 200 && response.data.success) {
          setPuzzles(response.data.data.puzzles);
        }
      } catch (error) {
        console.error('Error fetching puzzles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPuzzles();
  }, []);

  const handleOpen = (puzzle) => {
    setSelectedPuzzle(puzzle);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPuzzle(null);
    setRedeemError('');
  };

  const handleRedeemSubmit = (item_id) => {
    setRedeemLoading(true);
    setRedeemError('');

    console.log(selectedPuzzle.puzzle_id);
    console.log(item_id);
    submitPuzzleRedeem({ "puzzle_id": selectedPuzzle.puzzle_id, "item": item_id })
      .then(response => {
        // console.log(response);
        if (response.status === 200 && response.data.success) {
          // console.log('Redeem successful');
          // console.log(response.data);
          if (response.data.resultCode.item && response.data.resultCode.uuid) {
            navigate('/submit-redeem', {
              state: {
                item: response.data.resultCode.item,
                uuid: response.data.resultCode.uuid,
                puzzleId: selectedPuzzle.puzzle_id,
                itemName: response.data.resultCode.key || 'Your Reward'
              }
            });
          } else {
            try {
              setRedeemError(response.data.resultCode.message || 'Redemption failed');
            } catch {
              setRedeemError('Please Try Again Later');
            }
          }
        } else {
          setRedeemError(response.data.message || 'Redemption failed');
        }
      })
      .catch(error => {
        console.error('Error redeeming puzzle:', error);
        setRedeemError('An error occurred during redemption');
      })
      .finally(() => {
        setRedeemLoading(false);
        setOpenRedemption(false);
      });
  };

  const handleRedeem = () => {
    if (selectedPuzzle) {
      const unlockedPieces = selectedPuzzle.parts.filter(part => part.is_unlocked === 1).length;
      const isCompleted = unlockedPieces === selectedPuzzle.parts.length;

      if (!isCompleted) {
        setRedeemError('Complete the puzzle before redeeming');
        return;
      }

      setRedeemError('');
      setOpenRedemption(true);
    }
  };

  const handleGameSelect = (itemId) => {
    handleRedeemSubmit(itemId + 1);
  };

  const handlePieceClick = (isLocked, part) => {
    if (isLocked) {
      setCurrentPuzzlePart(part.part_id);
      setOpenEasterEgg(true);
    }
  };

  const handleUnlock = (unlockedPartId) => {
    setPuzzles(prevPuzzles =>
      prevPuzzles.map(puzzle => ({
        ...puzzle,
        parts: puzzle.parts.map(part =>
          part.part_id === unlockedPartId
            ? { ...part, is_unlocked: 1 }
            : part
        )
      }))
    );

    if (selectedPuzzle) {
      setSelectedPuzzle(prevSelectedPuzzle => ({
        ...prevSelectedPuzzle,
        parts: prevSelectedPuzzle.parts.map(part =>
          part.part_id === unlockedPartId
            ? { ...part, is_unlocked: 1 }
            : part
        )
      }));
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2, backgroundColor: '#121212', color: 'white', minHeight: '100vh' }}>
      <Typography variant="h5" gutterBottom>
        Puzzle
      </Typography>
      <Typography variant="body2" gutterBottom>
        Unlock puzzles by entering Easter egg codes to win exciting gifts!
      </Typography>

      <List>
        {puzzles.map((puzzle) => {
          const unlockedPieces = puzzle.parts.filter(part => part.is_unlocked === 1).length;
          const isCompleted = unlockedPieces === puzzle.parts.length;
          const inProgress = unlockedPieces > 0 && !isCompleted;

          return (
            <StyledListItem
              key={puzzle.puzzle_id}
              completed={isCompleted}
              onClick={() => handleOpen(puzzle)}
              sx={{ cursor: 'pointer' }}
            >
              <ListItemAvatar>
                <StyledAvatar>
                  <img
                    loading='lazy'
                    src={puzzle.partImg}
                    alt={puzzle.puzzle_name}
                  />
                </StyledAvatar>
              </ListItemAvatar>
              <ListItemText
                primary={puzzle.puzzle_name}
              />
              {(inProgress || isCompleted) && (
                <Typography color={inProgress ? "orange" : "white"}>
                  {isCompleted ? "Completed" : `${puzzle.parts.length - unlockedPieces} pieces left`}
                </Typography>
              )}
            </StyledListItem>
          );
        })}
      </List>

      <StyledDialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
      >
        {selectedPuzzle && (
          <>
            <Box>
              <HeaderButton>
                {selectedPuzzle.puzzle_name}
              </HeaderButton>
              <IconButton
                onClick={handleClose}
                sx={{
                  '&:hover': { backgroundColor: '#dbc69f' },
                  position: 'absolute',
                  top: '-20px',
                  right: '-10px',
                  border: '2px solid black',
                  color: 'black',
                  backgroundColor: '#FFE8BC'
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <CompactDialogContent>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(${getGridColumns(selectedPuzzle.parts.length)}, 1fr)`,
                  gap: 0,
                  maxWidth: '800px',
                  margin: '16px auto 0',
                  backgroundColor: '#fff',
                  padding: 2,
                  borderRadius: 2,
                  border: '2px solid black'
                }}
              >
                {selectedPuzzle.parts.map((part) => (
                  <Box key={part.part_id}>
                    <PuzzlePieceBox
                      isLocked={!part.is_unlocked}
                      onClick={() => handlePieceClick(!part.is_unlocked, part)}
                    >
                      <BlurredImage
                        src={part.partImg}
                        alt="Puzzle piece"
                        isLocked={!part.is_unlocked}
                      />
                      {!part.is_unlocked && (
                        <LockOverlay>
                          <LockIcon sx={{ fontSize: 40, color: 'white' }} />
                        </LockOverlay>
                      )}
                    </PuzzlePieceBox>
                  </Box>
                ))}
              </Box>

              {redeemError && (
                <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                  {redeemError}
                </Typography>
              )}
            </CompactDialogContent>
            <DialogActions sx={{ p: 2, justifyContent: 'center' }}>
              <StyledButton
                onClick={handleRedeem}
                variant="contained"
                disabled={redeemLoading}
              >
                {redeemLoading ? <CircularProgress size={24} color="inherit" /> : 'Redeem'}
              </StyledButton>
            </DialogActions>
          </>
        )}
      </StyledDialog>

      <EasterEggDialog
        open={openEasterEgg}
        onClose={() => {
          setOpenEasterEgg(false);
          setCurrentPuzzlePart(null);
        }}
        currentPuzzle={selectedPuzzle?.puzzle_id}
        currentPart={currentPuzzlePart}
        onUnlock={handleUnlock}
      />

      <RedemptionDialog
        open={openRedemption}
        onClose={() => setOpenRedemption(false)}
        onSelectGame={handleGameSelect}
      />
    </Box>
  );
};

export default Puzzle;