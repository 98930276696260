import React from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    Button,
    Box,
    Typography,
    styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: '#FFE8BC',
        borderRadius: 16,
        padding: theme.spacing(2),
        minWidth: 300,
        minHeight: 380,
        border: 'black 2px solid',
        overflow: 'visible'
    }
}));

const HeaderButton = styled(Box)(({ theme }) => ({
    backgroundColor: '#FFA800',
    color: 'black',
    padding: '8px 16px',
    borderRadius: 24,
    fontWeight: 600,
    border: 'black 2px solid',
    position: 'absolute',
    top: '-20px',
    left: '30%',
    maxWidth: 230
}));

const CodeDisplay = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff',
    border: '2px solid black',
    borderRadius: 8,
    padding: '12px 24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    margin: '20px 0'
}));

const CopyButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#FAAE1C',
    color: 'black',
    borderRadius: 16,
    padding: '10px',
    width: '100%',
    '&:hover': {
        backgroundColor: '#e69e1a',
    },
    position: 'absolute',
    bottom: '20px',
    left: "15%",
    maxWidth: '70%'
}));

const EasterCodeDialog = ({ open, onClose, code = '' }) => {
    const handleCopyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(code);
            // You could add a toast notification here if desired
        } catch (err) {
            console.error('Failed to copy text:', err);
        }
    };

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <Box>
                <HeaderButton>
                    Easter Egg Found!
                </HeaderButton>
                <IconButton
                    onClick={onClose}
                    sx={{
                        '&:hover': { backgroundColor: '#dbc69f' },
                        position: 'absolute',
                        top: '-20px',
                        right: '-10px',
                        border: '2px solid black',
                        color: 'black',
                        backgroundColor: '#FFE8BC'
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <DialogContent sx={{ p: 0 }}>
                <Typography
                    align="center"
                    variant="body2"
                    sx={{ mt: 10, color: 'black' }}
                >
                    You've discovered an easter egg! Here's your code: <br />Use it to unlock the puzzles to win an exciting gift! 
                </Typography>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 4
                }}>
                    <CodeDisplay>
                        <Typography
                            variant="h4"
                            sx={{
                                fontFamily: 'monospace',
                                letterSpacing: '0.2em',
                                color: 'black'
                            }}
                        >
                            {code}
                        </Typography>
                    </CodeDisplay>
                </Box>

                <CopyButton
                    onClick={handleCopyToClipboard}
                    variant="contained"
                    startIcon={<ContentCopyIcon />}
                >
                    Copy to Clipboard
                </CopyButton>
            </DialogContent>
        </StyledDialog>
    );
};

export default EasterCodeDialog;