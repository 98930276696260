import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    TextField,
    Button,
    Box,
    Typography,
    styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { submitPuzzlePiece } from '../apiCalls/eastereggs/api_v1_eastereggs';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: '#FFE8BC',
        borderRadius: 16,
        padding: theme.spacing(2),
        minWidth: 300,
        minHeight: 420,
        border: 'black 2px solid',
        overflow: 'visible'
    }
}));

const HeaderButton = styled(Box)(({ theme }) => ({
    backgroundColor: '#FFA800',
    color: 'black',
    padding: '8px 16px',
    borderRadius: 24,
    fontWeight: 600,
    border: 'black 2px solid',
    position: 'absolute',
    top: '-20px',
    left: '30%',
    maxWidth: 230
}));

const CodeInput = styled(TextField)(({ theme }) => ({
    width: 40,
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
            borderBottomColor: 'black',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
            borderBottomColor: 'black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
            borderBottomColor: 'black',
        },
    },
    '& input': {
        textAlign: 'center',
        padding: '8px',
        color: 'black'
    },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#FAAE1C',
    color: 'black',
    borderRadius: 16,
    padding: '10px',
    width: '70%',
    '&:hover': {
        backgroundColor: '#e69e1a',
    },
    marginTop: '15px' 
}));

const EasterEggDialog = ({ open, onClose, currentPuzzle, currentPart, onUnlock }) => {
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (index) => (event) => {
        const newValue = event.target.value;
        if (newValue.length <= 1) {
            const newCode = [...code];
            newCode[index] = newValue.toUpperCase();
            setCode(newCode);
            setError('');

            // Auto-focus next input
            if (newValue && index < 5) {
                const nextInput = document.querySelector(`input[name="code-${index + 1}"]`);
                if (nextInput) nextInput.focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const easterEggCode = code.join('');
        
        try {
            const response = await submitPuzzlePiece({
                puzzle_id: currentPuzzle,
                puzzle_part_id: currentPart,
                easter_egg_code: easterEggCode.toLowerCase()
            });

            if (response.data.success) {
                onUnlock && onUnlock(currentPart);
                onClose();
            } else {
                setError('Invalid code. Please try again.');
            }
        } catch (error) {
            setError(error.response.data.message);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setCode(['', '', '', '', '', '']);
        setError('');
        onClose();
    };

    return (
        <StyledDialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
            <Box>
                <HeaderButton>
                    Complete the Code
                </HeaderButton>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        '&:hover': { backgroundColor: '#dbc69f' },
                        position: 'absolute',
                        top: '-20px',
                        right: '-10px',
                        border: '2px solid black',
                        color: 'black',
                        backgroundColor: '#FFE8BC'
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <DialogContent sx={{ 
                p: 0, 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%'
            }}>
                <form onSubmit={handleSubmit} style={{ 
                    width: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'space-between',
                    paddingBottom: '20px'
                }}>
                    <Box sx={{ mt: 10, width: '100%' }}>
                        <Typography
                            align="center"
                            variant="body2"
                            sx={{ color: 'black' }}
                        >
                            Enter the code you get upon clicking easter eggs in videos and complete to win exciting gift!
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1,
                        mt: 8,
                        mb: 4
                    }}>
                        {code.map((value, index) => (
                            <CodeInput
                                key={index}
                                name={`code-${index}`}
                                value={value}
                                onChange={handleChange(index)}
                                variant="outlined"
                                autoComplete='false'
                                inputProps={{
                                    maxLength: 1,
                                    style: { textAlign: 'center' }
                                }}
                                disabled={loading}
                            />
                        ))}
                    </Box>

                    <Box sx={{ minHeight: '40px'}}>
                        {error && (
                            <Typography
                                align="center"
                                color="error"
                                variant="body2"
                            >
                                {error}
                            </Typography>
                        )}
                    </Box>

                    <SubmitButton 
                        type="submit" 
                        variant="contained"
                        disabled={loading || code.some(c => !c)}
                    >
                        {loading ? 'Submitting...' : 'Submit'}
                    </SubmitButton>
                </form>
            </DialogContent>
        </StyledDialog>
    );
};

export default EasterEggDialog;