import { Box, Card, CardMedia, Typography, styled } from "@mui/material";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import { FullscreenTwoTone } from "@mui/icons-material";
import { React, useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { sendVideoAccessTime, checkEasterEggAvailability } from "../apiCalls/eastereggs/api_v1_eastereggs";
import EasterCodeDialog from './EasterCodeDialog';

const ProgressBar = styled('div')({
    width: '100%',
    height: '3px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    cursor: 'pointer',
    position: 'absolute',
    bottom: '40px',
    left: 0,
    '&:hover': {
        height: '5px',
    }
});

const Progress = styled('div')({
    height: '100%',
    backgroundColor: '#FFD700',
    transition: 'width 0.1s linear',
});

function VideoPlayer({ videoUrl, title }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const podcastId = queryParams.get('uuid');
    const videoRef = useRef(null);
    const [currentTime, setCurrentTime] = useState("00:00");
    const [duration, setDuration] = useState("00:00");
    const [isPlaying, setIsPlaying] = useState(true);
    const [progress, setProgress] = useState(0);
    const [isBuffering, setIsBuffering] = useState(false);
    // Easter egg tracking state
    const [easterEggChecked, setEasterEggChecked] = useState(false);
    const [watchedSeconds, setWatchedSeconds] = useState(0);
    const lastTimeUpdateRef = useRef(0);
    // New state for Easter egg dialog
    const [isEasterEggDialogOpen, setIsEasterEggDialogOpen] = useState(false);
    const [easterEggCode, setEasterEggCode] = useState('');

    const styles = {
        card: {
            border: "none",
            borderRadius: "15px",
            background: "transparent",
            boxShadow: "none",
            position: "relative",
            overflow: "hidden",
            color: "white",
        },
        videoWrapper: {
            position: 'relative',
            width: '100%',
            backgroundColor: '#000',
        },
        controls: {
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: "8px 16px",
            background: "linear-gradient(to top, rgba(0,0,0,0.7), transparent)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: '40px',
        },
        icons: {
            color: 'white',
            fontSize: '28px',
            cursor: 'pointer',
            marginRight: '10px',
            '&:hover': {
                color: '#FFD700',
            }
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    useEffect(() => {
        const handleMetadataLoaded = () => {
            const videoDuration = Math.floor(videoRef.current.duration);
            // console.log('[Easter Egg] Video duration:', videoDuration, 'seconds');

            sendVideoAccessTime({
                podcastId,
                video_duration: videoDuration.toString()
            }).then(() => {
                // console.log('[Easter Egg] Initial video access time sent');
                // Reset all Easter egg related states
                setWatchedSeconds(0);
                lastTimeUpdateRef.current = 0;
                setEasterEggChecked(false);
            }).catch(err => {
                console.error('[Easter Egg] Error sending video access time:', err);
            });
        };

        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener('loadedmetadata', handleMetadataLoaded);
            return () => {
                videoElement.removeEventListener('loadedmetadata', handleMetadataLoaded);
            };
        }
    }, []);

    useEffect(() => {
        const updateTimer = () => {
            if (videoRef.current) {
                const current = videoRef.current.currentTime;
                const videoDuration = videoRef.current.duration;
                const percent = (current / videoDuration) * 100;

                // Update UI display
                setCurrentTime(formatTime(current));
                setDuration(formatTime(videoDuration));
                setProgress(percent);

                // Track actual watch time for Easter egg
                const timeDiff = current - lastTimeUpdateRef.current;
                if (timeDiff > 0 && timeDiff < 1.5) {
                    setWatchedSeconds(prev => {
                        const newWatchedSeconds = prev + timeDiff;
                        // console.log('[Easter Egg] Watched seconds:', Math.floor(newWatchedSeconds));

                        // Check for Easter egg at 40% watched
                        const fortyPercentDuration = videoDuration * 0.5;
                        if (!easterEggChecked && newWatchedSeconds >= fortyPercentDuration) {
                            // console.log('[Easter Egg] 40% threshold reached:', Math.floor(newWatchedSeconds), 'seconds');
                            
                            checkEasterEggAvailability({
                                podcastId,
                                user_video_progress: Math.floor(newWatchedSeconds).toString()
                            }).then((response) => {
                                if (response.data.easter_egg) {
                                    if (document.fullscreenElement) {
                                        document.exitFullscreen().catch((err) => {
                                            console.error('Error exiting fullscreen:', err);
                                        });
                                    }
                                    // Set the easter egg code and open the dialog
                                    setEasterEggCode(response.data.easter_egg);
                                    setIsEasterEggDialogOpen(true);
                                    // Pause the video when showing the dialog
                                    if (videoRef.current) {
                                        videoRef.current.pause();
                                        setIsPlaying(false);
                                    }
                                }
                            }).catch(err => {
                                console.error('[Easter Egg] Check failed:', err);
                            });
                            
                            setEasterEggChecked(true);
                        }

                        return newWatchedSeconds;
                    });
                }
                lastTimeUpdateRef.current = current;
            }
        };

        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener("timeupdate", updateTimer);
            return () => {
                videoElement.removeEventListener("timeupdate", updateTimer);
            };
        }
    }, [podcastId, easterEggChecked]);

    const handleBuffering = () => {
        setIsBuffering(true);
    };

    const handleBufferingComplete = () => {
        setIsBuffering(false);
    };

    const handlePlay = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setIsPlaying(true);
            } else {
                videoRef.current.pause();
                setIsPlaying(false);
            }
        }
    };

    const HandleSound = () => {
        if (videoRef.current) {
            if (videoRef.current.volume === 0) {
                videoRef.current.volume = 1;
            } else {
                videoRef.current.volume = 0;
            }
        }
    };

    const handleVideoDoubleClick = () => {
        const video = videoRef.current;
        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.webkitRequestFullscreen) {
            video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) {
            video.msRequestFullscreen();
        }
    };

    const handleProgressBarClick = (e) => {
        if (!videoRef.current) return;

        const progressBar = e.currentTarget;
        const rect = progressBar.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const percentage = (x / rect.width) * 100;
        const time = (percentage / 100) * videoRef.current.duration;

        videoRef.current.currentTime = time;
    };

    const handleEasterEggDialogClose = () => {
        setIsEasterEggDialogOpen(false);
        // Optionally resume video playback when dialog is closed
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    return (
        <>
            <Card sx={styles.card}>
                <Box sx={styles.videoWrapper}>
                    <CardMedia
                        component="video"
                        controls={false}
                        src={videoUrl}
                        ref={videoRef}
                        playsInline={true}
                        onContextMenu={(e) => e.preventDefault()}
                        onWaiting={handleBuffering}
                        onPlaying={handleBufferingComplete}
                        sx={{ width: '100%' }}
                        onClick={handlePlay}
                        onDoubleClick={handleVideoDoubleClick}
                        autoPlay={true}
                    />

                    <ProgressBar onClick={handleProgressBarClick}>
                        <Progress style={{ width: `${progress}%` }} />
                    </ProgressBar>

                    <Box sx={styles.controls}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <PlayCircleIcon
                                onClick={handlePlay}
                                sx={styles.icons}
                            />
                            <VolumeUpRoundedIcon
                                onClick={HandleSound}
                                sx={styles.icons}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                variant="body2"
                                sx={{ color: 'white', mr: 1 }}
                            >
                                {currentTime} / {duration}
                            </Typography>
                            <FullscreenTwoTone
                                onClick={handleVideoDoubleClick}
                                sx={styles.icons}
                            />
                        </Box>
                    </Box>

                    {isBuffering && (
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            padding: '10px',
                            borderRadius: '5px'
                        }}>
                            Loading...
                        </Box>
                    )}
                </Box>
            </Card>

            <EasterCodeDialog
                open={isEasterEggDialogOpen}
                onClose={handleEasterEggDialogClose}
                code={easterEggCode}
            />
        </>
    );
}

export default VideoPlayer;