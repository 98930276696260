import React, { useState, useEffect } from 'react';
import {
    Box,
    Tabs,
    Tab,
    styled,
    Tooltip
} from '@mui/material';
import GamezTips from '../components/GamezTips';
import GamezBites from '../components/GamezBites';
import GamezGuides from '../components/GamezGuides';
import { useMediaQuery } from '@mui/material';
import TestSkillsDialog from '../components/TestSkillsDialog';

const StyledTabs = styled(Tabs)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    '& .MuiTabs-indicator': {
        backgroundColor: '#FAAE1C',
    },
    '& .MuiTab-root': {
        color: theme.palette.text.secondary,
        '&.Mui-selected': {
            color: '#FAAE1C',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
            color: theme.palette.text.disabled,
        }
    }
}));

function Game() {
    const [tabValue, setTabValue] = useState(0);
    const [userSkillLevel, setUserSkillLevel] = useState('beginner');
    const [isSkillDialogOpen, setIsSkillDialogOpen] = useState(false);
    const isDesktop = useMediaQuery('(min-width:900px)');

    useEffect(() => {
        document.title = 'Gamez | Gamez Academy';
        const skillLevel = sessionStorage.getItem('skillLevel') || 'beginner';
        setUserSkillLevel(skillLevel);
        
        if (skillLevel === 'beginner') setTabValue(0);
        else if (skillLevel === 'rookie') setTabValue(1);
        else if (skillLevel === 'pro') setTabValue(2); 
    }, []);

    const canAccessTab = (tabIndex) => {
        const levelAccess = {
            'beginner': [0],
            'rookie': [0, 1],
            'pro': [0, 1, 2]
        };
        return levelAccess[userSkillLevel]?.includes(tabIndex) || false;
    };

    const handleTabChange = (event, newValue) => {
        if (canAccessTab(newValue)) {
            setTabValue(newValue);
        } else {
            setIsSkillDialogOpen(true);
        }
    };

    const getTabProps = (index) => {
        const isAccessible = canAccessTab(index);
        return {
            disabled: false,
            label: (
                <Tooltip
                    title={!isAccessible ? `Unlock this by reaching ${index === 1 ? 'Rookie' : 'Pro'} level` : ''}
                    arrow
                    placement="top"
                >
                    <span>{index === 0 ? 'Beginner' : index === 1 ? 'Rookie' : 'Pro'}</span>
                </Tooltip>
            )
        };
    };

    const getContentForTab = (tabIndex) => {
        const tabToSkillLevel = {
            0: 'beginner',
            1: 'rookie',
            2: 'pro'
        };
        
        return tabToSkillLevel[tabIndex] || 'beginner';
    };

    return (
        <Box
            sx={{
                maxWidth: '100%',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
            }}
        >
            <StyledTabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                aria-label="skill level tabs"
            >
                <Tab {...getTabProps(0)} />
                <Tab {...getTabProps(1)} />
                <Tab {...getTabProps(2)} />
            </StyledTabs>

            {!isDesktop && <GamezTips skillLevel={getContentForTab(tabValue)} />}
            <GamezBites skillLevel={getContentForTab(tabValue)} />
            <GamezGuides skillLevel={getContentForTab(tabValue)} />

            <TestSkillsDialog open={isSkillDialogOpen} onClose={() => { setIsSkillDialogOpen(false) }} />
        </Box>
    );
}

export default Game;