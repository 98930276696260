import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  Box,
  Typography,
  styled,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import fortnite from '../static/img/redeem/fortnite_new.png';
import pubg from '../static/img/redeem/pubg1.jpeg';
import minecraft from '../static/img/redeem/minecraft1.png';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#FFE8BC',
    borderRadius: 16,
    padding: theme.spacing(2),
    minWidth: 300,
    minHeight: 380,
    border: 'black 2px solid',
    overflow: 'visible',
    [theme.breakpoints.down('sm')]: {
      minWidth: '95%',
      margin: '10px',
      padding: theme.spacing(1),
    }
  }
}));

const HeaderButton = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFA800',
  color: 'black',
  padding: '8px 16px',
  borderRadius: 24,
  fontWeight: 600,
  border: 'black 2px solid',
  position: 'absolute',
  top: '-20px',
  left: '15%',
  maxWidth: 230,
  [theme.breakpoints.down('sm')]: {
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '0.9rem',
    padding: '6px 12px',
    whiteSpace: 'nowrap',
  }
}));

const GameItem = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  border: '2px solid black',
  borderRadius: 8,
  margin: '8px',
  cursor: 'pointer',
  transition: 'transform 0.2s',
  display: 'flex',
  alignItems: 'center',
  paddingRight: 16,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    paddingRight: 0,
    paddingBottom: 12,
    textAlign: 'center',
  },
  '&:hover': {
    transform: 'scale(1.02)',
    backgroundColor: '#f5f5f5'
  }
}));

const GameImage = styled('img')(({ theme }) => ({
  width: '120px',
  height: '100px',
  objectFit: 'cover',
  borderRadius: 4,
  marginRight: 16,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'auto',
    marginRight: 0,
    marginBottom: 12,
  }
}));

const RedemptionDialog = ({ open, onClose, onSelectGame }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const games = [
    {
      name: "PUBG Mobile UC (300)",
      thumbnail: pubg,
      details: "Information not available"
    },
    {
      name: "Fortnite 1000 V-Bucks",
      thumbnail: fortnite,
      details: "Information not available"
    },
    {
      name: "Minecraft Minecoins (1720)",
      thumbnail: minecraft,
      details: "Information not available"
    }
  ];

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box>
        <HeaderButton>
          Choose Your Reward!
        </HeaderButton>
        <IconButton
          onClick={onClose}
          sx={{
            '&:hover': { backgroundColor: '#dbc69f' },
            position: 'absolute',
            top: '-20px',
            right: '-10px',
            border: '2px solid black',
            color: 'black',
            backgroundColor: '#FFE8BC',
            [theme.breakpoints.down('sm')]: {
              top: '-16px',
              right: '-6px',
              padding: '4px',
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent 
        sx={{ 
          p: 0, 
          mt: isSmallScreen ? 5 : 4,
          overflowY: 'auto',
          maxHeight: '70vh'
        }}
      >
        <Typography
          align="center"
          variant="body1"
          sx={{ 
            mt: 2, 
            mb: 3, 
            color: 'black',
            px: isSmallScreen ? 1 : 2,
            fontSize: isSmallScreen ? '0.9rem' : 'inherit'
          }}
        >
          Select a game reward to redeem your completed puzzle!
        </Typography>
        <Box sx={{ px: isSmallScreen ? 1 : 2 }}>
          {games.map((game, index) => (
            <GameItem key={index} onClick={() => onSelectGame(index)}>
              <GameImage
                src={game.thumbnail}
                alt={game.name}
              />
              <Typography
                variant="subtitle2"
                sx={{ 
                  color: 'black', 
                  fontWeight: 600,
                  width: isSmallScreen ? '100%' : 'auto',
                  textAlign: isSmallScreen ? 'center' : 'left'
                }}
              >
                {game.name}
              </Typography>
            </GameItem>
          ))}
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default RedemptionDialog;