import React, { useState, useEffect } from 'react';
import { 
    Box, 
    AppBar, 
    Typography, 
    Drawer, 
    List, 
    ListItem, 
    ListItemButton, 
    ListItemText,
    ListItemIcon,
    Divider
} from '@mui/material';
import {
    Home as HomeIcon,
    AccountCircle as ProfileIcon,
    LibraryBooks as TestIcon,
    Menu as MenuIcon,
    CardGiftcardOutlined,
    Settings as SettingsIcon,
    Description as TermsIcon,
    Lock as PrivacyIcon
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import logoImg from '../static/img/header/logo.svg';
import { useTheme } from '@mui/material/styles';
import TestSkillsDialog from '../components/TestSkillsDialog';

function LayoutMobile({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const gameUuid = queryParams.get('game');
    const [value, setValue] = useState(location.pathname);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [openTestSkills, setOpenTestSkills] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        setValue(location.pathname);
    }, [location.pathname]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate(newValue);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleNavigation = (action) => {
        if (typeof action === 'function') {
            action();
        } else if (typeof action === 'string') {
            navigate(action);
        }
        setIsSidebarOpen(false);
    };

    const menuItems = [
        { icon: HomeIcon, label: "Home", action: '/' },
        { icon: ProfileIcon, label: "Profile", action: '/profile' },
        { 
            icon: TestIcon, 
            label: "Test your Skills", 
            action: () => setOpenTestSkills(true) 
        },
        { 
            icon: CardGiftcardOutlined, 
            label: "Win a Gift", 
            action: '/puzzle'
        },
        { type: 'divider' },
        // { icon: SettingsIcon, label: "Settings", action: '/settings' },
        { icon: TermsIcon, label: "Terms of Service", action: '/terms' },
        // { icon: PrivacyIcon, label: "Privacy Policy", action: '/privacy' }
    ];

    return (
        <Box sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        }}>
            <AppBar
                position="static"
                sx={{
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    boxShadow: 'none',
                    maxHeight: '150px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 10px'
                }}
            >
                {location.pathname === '/game' ?
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <ArrowBackRounded
                            onClick={() => { navigate('/'); window.location.reload(); }}
                            sx={{ cursor: 'pointer', color: 'white' }}
                        />
                        <Typography variant="h6" sx={{ textAlign: 'left', color: 'white', fontFamily: "'Azonix', sans-serif" }}>{gameUuid}</Typography>
                    </Box>
                    :
                    <img 
                        src={logoImg} 
                        alt="GamezAcademy" 
                        style={{ height: 'auto', width: '120px', padding: '8px' }} 
                        onClick={() => { navigate('/'); window.location.reload(); }} 
                    />
                }
                <MenuIcon
                    onClick={toggleSidebar}
                    sx={{ cursor: 'pointer' }}
                    color='primary'
                />
            </AppBar>
            
            <Box sx={{
                flex: 1,
                overflow: 'auto',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: theme.palette.background.paper,
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
            }}>
                {children}
            </Box>

            <Drawer
                anchor="right"
                open={isSidebarOpen}
                onClose={toggleSidebar}
            >
                <Box
                    role="presentation"
                    sx={{ 
                        width: '250px', 
                        backgroundColor: '#1e1e1e', 
                        height: '100%', 
                        display: 'flex', 
                        flexDirection: 'column' 
                    }}
                >
                    <List>
                        {menuItems.map((item, index) => (
                            item.type === 'divider' ? (
                                <Divider key={`divider-${index}`} sx={{ my: 2 }} />
                            ) : (
                                <ListItem key={item.label} disablePadding>
                                    <ListItemButton 
                                        onClick={() => handleNavigation(item.action)}
                                        sx={{
                                            color: location.pathname === item.action ? '#FFD700' : 'text.primary',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            },
                                        }}
                                    >
                                        <ListItemIcon>
                                            <item.icon 
                                                sx={{ 
                                                    color: location.pathname === item.action ? '#FFD700' : 'primary.main'
                                                }} 
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={item.label} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        ))}
                    </List>
                </Box>
            </Drawer>

            <TestSkillsDialog 
                open={openTestSkills} 
                onClose={() => setOpenTestSkills(false)} 
            />
        </Box>
    );
}

export default LayoutMobile;