import React, { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import LayoutDesktop from './LayoutDesktop';
import LayoutMobile from './LayoutMobile';
import { getUserInfo } from '../apiCalls/user_profile/api_v1_profile';

const Layout = ({ children }) => {
  const isDesktop = useMediaQuery('(min-width:900px)');
  
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await getUserInfo();
        if (response.status === 200) {
          sessionStorage.setItem('skillLevel', response.data.skill_level.toLowerCase());
          sessionStorage.setItem('last_played_level', response.data.latest_played_level);
          // console.log(response.data)
        }
      } catch (error) {
        sessionStorage.setItem('skillLevel', 'beginner');
      }
    };
    
    const hasReloaded = sessionStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      sessionStorage.setItem('hasReloaded', 'true');
      window.location.reload();
      return;
    }
    
    fetchUserInfo();
  }, []);

  return isDesktop ? (
    <LayoutDesktop>{children}</LayoutDesktop>
  ) : (
    <LayoutMobile>{children}</LayoutMobile>
  );
};

export default Layout;