import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { verifyStatus } from './api_v1_jt';

const ProtectedRoutes = ({ element: Component, ...rest }) => {
    const { auth, fetchNewRefreshToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleAuth = async () => {
            if (!sessionStorage.getItem("firstLoad")) {
                localStorage.clear(); // Clear local storage on first app load
                sessionStorage.setItem("firstLoad", "true"); // Set flag so it doesn't clear again
            }
            if (auth.accessToken) {
                setLoading(false);
                return;
            }

            const urlParams = new URLSearchParams(window.location.search);
            const msisdn = urlParams.get('a');

            if (msisdn) {
                const loginStatus = await verifyStatus(msisdn);
                console.log(loginStatus)

                if (loginStatus.data.data.status == 1 || loginStatus.data.data.status == 2) {
                    const newAuth = await fetchNewRefreshToken(msisdn);
                    console.log(newAuth)
                    if (newAuth) {
                        console.log(newAuth);
                        setLoading(false);
                        window.location.reload();
                        window.location.reload();
                        return;
                    }
                    return;
                }
                else {
                    window.location.href = process.env.REACT_APP_LOGIN_URL;
                }
            }
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        };

        handleAuth();
    }, [auth.accessToken, fetchNewRefreshToken]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return auth.accessToken ? <Component {...rest} /> : null;
};

export default ProtectedRoutes;
