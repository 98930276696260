import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./views/Home";
import Layout from "./layouts/Layout";
import ProtectedRoutes from "./apiCalls/jwt/ProtectedRoutes";
import { AuthProvider } from "./apiCalls/jwt/AuthContext";
import Shorts from "./views/Shorts";
import Podcast from "./views/Podcast";
import Game from "./views/Game";
import Story from "./views/Story";
import Puzzle from "./views/Puzzle";
import GAFps from "./views/GAFps";
import Profile from "./views/Profile";
import SubmitRedeem from "./views/SubmitRedeem";
import ThankYou from "./views/Thankyou";
import GoogleAnalyticsConditional from "./components/GoogleAnalyticsConditional";
import Unsub from "./components/unsub";
import Terms from "./views/Terms";

function App() {
    return (
        <>
            <AuthProvider>
                <BrowserRouter>
                    <GoogleAnalyticsConditional
                        trackingId="G-53NR18LH4P"
                    />
                    <Layout>
                        <Routes>
                            <Route path="/" element={<ProtectedRoutes element={Home} />} />
                            <Route path="/bites" element={<ProtectedRoutes element={Shorts} />} />
                            <Route path="/guides" element={<ProtectedRoutes element={Podcast} />} />
                            <Route path="/game" element={<ProtectedRoutes element={Game} />} />
                            <Route path="/profile" element={<ProtectedRoutes element={Profile} />} />
                            <Route path="/tips" element={<ProtectedRoutes element={Story} />} />
                            <Route path="/puzzle" element={<ProtectedRoutes element={Puzzle} />} />
                            <Route path="/ga-fps-game" element={<ProtectedRoutes element={GAFps} />} />
                            <Route path="/submit-redeem" element={<ProtectedRoutes element={SubmitRedeem} />} />
                            <Route path="/thankyou" element={<ProtectedRoutes element={ThankYou} />} />
                            <Route path="/unsubscribe" element={<Unsub />} />
                            <Route path="/terms" element={<Terms />} />
                        </Routes>
                    </Layout>

                </BrowserRouter>
            </AuthProvider>
        </>
    );
}

export default App;