import axiosClient from "../axiosClient"

export function sendVideoAccessTime(data) {
    let bearerTokenHeader = sessionStorage.getItem('accessToken');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post(`/easter-egg/access-time`, JSON.stringify(data));
}

export function checkEasterEggAvailability(data) {
    let bearerTokenHeader = sessionStorage.getItem('accessToken');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post(`/easter-egg/validate-time`, JSON.stringify(data));
}

export function getPuzzleList(data) {
    let bearerTokenHeader = sessionStorage.getItem('accessToken');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get(`/puzzles`, JSON.stringify(data));
}

export function submitPuzzlePiece(data) {
    let bearerTokenHeader = sessionStorage.getItem('accessToken');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post(`/puzzles/unlock-part`, JSON.stringify(data));
}

export function submitPuzzleRedeem(data) {
    let bearerTokenHeader = sessionStorage.getItem('accessToken');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post(`/puzzles/redeem-item`, JSON.stringify(data));
}

export function submitPuzzleClaim(data) {
    let bearerTokenHeader = sessionStorage.getItem('accessToken');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post(`/puzzles/redeem-claim`, JSON.stringify(data));
}
