import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalyticsConditional = ({ trackingId }) => {
    const location = useLocation();

    useEffect(() => {
        const currentTitle = document.title;

        const initializeGtag = (trackingId) => {
            // If the Google Analytics script hasn't been loaded, add it
            if (!window.dataLayer) {
                const script = document.createElement('script');
                script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
                script.async = true;
                document.head.appendChild(script);
            }

            // Initialize GA with the correct tracking ID
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }

            gtag('js', new Date());
            gtag('config', trackingId, {
                page_path: location.pathname + location.search,
            });
        };

        // Reinitialize with the correct tracking ID based on the current title
        if (currentTitle !== "Gamez Academy") {
            initializeGtag(trackingId); // Use home tracking ID for all other titles
        }
    }, [location, trackingId]); // Re-run effect when location changes

    return null; // This component does not render any visible content
};

export default GoogleAnalyticsConditional;