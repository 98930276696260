import React from 'react';
import { 
  Typography, 
  Divider, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Paper,
  Container
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

function Terms() {
  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, my: 3 }}>
        <Typography variant="h4" gutterBottom>
          Terms & Conditions
        </Typography>
        
        <Typography variant="body1" paragraph>
          By using Gamez Academy (Website) you agree to these terms and conditions, privacy policy, returns and refund policy of the Website. Please read the Terms and Conditions carefully before using Gamez Academy Service.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          INTRODUCTION
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Gamez Academy website or gamezacademy.pk also hereby known as "we", "us" or "Gamez Academy". We are a web based services of mobile/computer/console gaming industry and these are the terms and conditions governing your access and use of Gamez Academy along with its related sub-domains, website and content (the "Website"). By using the Website you hereby accept these terms and conditions (including the linked information herein) and represent that you agree to comply with these terms and conditions (the "User Agreement").
        </Typography>
        <Typography variant="body1" paragraph>
          This User Agreement is a legal agreement between you ("you", "the consumer") and Gamez Academy platform (website, as owned by Technowiz Private Limited (incorporated at Islamabad, Pakistan, referred to as "Gamez Academy" "we" or "us").
        </Typography>
        <Typography variant="body1" paragraph>
          By agreeing to these terms and conditions, you hereby recognize that all the content provided on Gamez Academy is protected by Intellectual Property Rights and you should not use this content in any other form other than it is intended when accessed on the website i.e. to learn how to improve your gaming skills.
        </Typography>
        <Typography variant="body1" paragraph>
          The Website reserves the right to change, modify, add, or remove portions of these Terms and Conditions at any time without any prior notification. Changes will be effective when posted on the Website with no other notice provided. Please check these Terms and Conditions regularly for updates. Your continued use of the Website following the posting of changes to Terms and Conditions of use constitutes your acceptance of those changes.
        </Typography>
        
        <Divider sx={{ my: 3 }} />
        
        <Typography variant="h5" gutterBottom>
          CONDITIONS OF USE
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          YOUR ACCOUNT
        </Typography>
        <Typography variant="body1" paragraph>
          To access content offered by the website, we may require that you create an account with us or provide personal information to complete the creation of an account. We may at any time in our sole and absolute discretion, invalidate the username and/or password without giving any reason or prior notice and shall not be liable or responsible for any losses suffered by, caused by, arising out of, in connection with or by reason of such request or invalidation.
        </Typography>
        <Typography variant="body1" paragraph>
          You are responsible for maintaining the confidentiality of your user identification, account details and related private information. You agree to accept this responsibility and ensure your account and its related details are maintained securely at all times and all necessary steps are taken to prevent misuse of your account.
        </Typography>
        <Typography variant="body1" paragraph>
          You should inform us immediately if you have any reason to believe that your account has become known to anyone else, or if the account is being, or is likely to be, used in an unauthorized manner.
        </Typography>
        <Typography variant="body1" paragraph>
          You agree and acknowledge that any use of the Website and content and/or any access to private information, data or communications using your account shall be deemed performed by you. You agree to be bound by any access of the Website and/or use of any content offered by the Website (whether such access or use are authorized by you or not).
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          PRIVACY
        </Typography>
        <Typography variant="body1" paragraph>
          Please review our Privacy Agreement, which also governs your visit to the Website. The personal information / data provided to us by you or your use of the Website will be treated as strictly confidential, in accordance with the Privacy Agreement and applicable laws and regulations. If you object to your information being transferred or used in the manner specified in the Privacy Agreement, please do not use the Website.
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          PLATFORM FOR COMMUNICATION
        </Typography>
        <Typography variant="body1" paragraph>
          You agree, understand and acknowledge that the Website is an online platform that enables you to consume content at the price indicated therein at any time from any location using a payment method 'Direct Carrier Billing'.
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          CONTINUED AVAILABILITY OF THE WEBSITE / MOBILE APPLICATION
        </Typography>
        <Typography variant="body1" paragraph>
          We will do our utmost to ensure that access to the Website / mobile application is consistently available and is uninterrupted and error-free. However, due to the nature of the Internet and the nature of the Website, this cannot be guaranteed. Additionally, your access to the Website may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction.
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          LICENSE TO ACCESS THE WEBSITE
        </Typography>
        <Typography variant="body1" paragraph>
          We require that by accessing the Website, you confirm that you can form legally binding contracts and therefore you confirm that you are at least 18 years of age or are accessing the Website under the supervision of a parent or legal guardian. We grant you a non-transferable, revocable and non-exclusive license to use the Website, in accordance with the Terms and Conditions described herein, for the purposes of viewing content.
        </Typography>
        <Typography variant="body1" paragraph>
          Commercial use or use on behalf of any third party is prohibited, except as explicitly permitted by us in advance. Any breach of these Terms and Conditions shall result in the immediate revocation of the license granted in this paragraph without notice to you.
        </Typography>
        <Typography variant="body1" paragraph>
          We grant you a limited license to access and make personal use of this Website, not to download (excluding page caches) or modify the Website or any portion of it in any manner. This license does not include any resale or commercial use of this Website or its contents; any collection and use of any content of this Website; any downloading or copying of content for any other benefit or use; or any use of data mining, robots, or similar data gathering and extraction tools.
        </Typography>
        <Typography variant="body1" paragraph>
          This Website or any portion of it (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, distributed or otherwise exploited for any commercial purpose without express written consent by us.
        </Typography>

        <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
          The other restricted activities may include, but not limited to the following:
        </Typography>
        
        <List dense>
          <ListItem>
            <ListItemIcon>
              <CircleIcon sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="Impersonate any person or entity or to falsely state or otherwise misrepresent your affiliation with any person or entity." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="Use the Website for any illegal purposes." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="Attempt to gain unauthorized access to or otherwise interfere or disrupt other computer systems or networks connected to the Platform or Services." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="Interfere with another's utilization and enjoyment of the Website" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="Post, promote or transmit through the Website any prohibited materials as deemed illegal by The Islamic Republic of Pakistan." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="Use or upload, in any way, any software or material that contains, or which you have reason to suspect that contains, viruses, damaging components, malicious code or harmful components which may impair or corrupt the Website data or damage or interfere with the operation of another Customer's computer or mobile device or the Website and use the Website other than in conformance with the acceptable usage policies of any connected computer networks, any applicable Internet standards and any other applicable laws." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="You agree and undertake not to perform restricted activities listed within this section; undertaking these activities will result in an immediate cancellation of your account and in severe cases may also result in legal action" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary="Refusal to comply with the Terms and Conditions described herein or any other guidelines and policies always related to the use of the Website as available on the Website." />
          </ListItem>
        </List>
        
        <Divider sx={{ my: 3 }} />
        
        <Typography variant="h5" gutterBottom>
          YOUR CONDUCT
        </Typography>
        <Typography variant="body1" paragraph>
          You must not use the website in any way that causes, or is likely to cause, the or access to it to be interrupted, damaged or impaired in any way. You must not engage in activities that could harm or potentially harm the Website, its employees, officers, representatives, stakeholders or any other party directly or indirectly associated with the Website or access to it to be interrupted, damaged or impaired in any way.
        </Typography>
        <Typography variant="body1" paragraph>
          You are strictly prohibited from using the Website for fraudulent purposes, or in connection with a criminal offense or other unlawful activity to send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of Pakistan or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any "spam".
        </Typography>
        
        <Divider sx={{ my: 3 }} />
        
        {/* Continue with the remaining sections */}
        <Typography variant="h5" gutterBottom>
          YOUR SUBMISSION
        </Typography>
        <Typography variant="body1" paragraph>
          Anything that you submit to the Website and/or provide to us, including but not limited to, questions, reviews, comments, and suggestions (collectively, "Submissions") will become our sole and exclusive property and shall not be returned to you. In addition to the rights applicable to any Submission, when you post comments or reviews to the Website, you also grant us the right to use the name that you submit, in connection with such review, comment, or other content.
        </Typography>
        <Typography variant="body1" paragraph>
          You shall not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of any Submissions. We may, but shall not be obligated to, remove or edit any Submissions without any notice or legal course applicable to us in this regard.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          CLAIMS AGAINST INFRINGING CONTENT
        </Typography>
        <Typography variant="body1" paragraph>
          We respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement, please write to us at our available feedback form and we will make all reasonable efforts to address your concern within a reasonable amount of time. Please ensure to provide your name, address, contact information and as many relevant details of the claim including name of infringing party, instances of infringement, proof of infringement amongst other. Please note that providing incomplete details will render your claim invalid and unusable for legal purposes. In addition, providing false or misleading information may be considered a legal offense and may be followed by legal proceedings.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          INDEMNITY
        </Typography>
        <Typography variant="body1" paragraph>
          You shall indemnify and hold harmless Gamez Academy as owned by Technowiz Private Limited, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney's fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms and Conditions or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party. You hereby expressly release Gamez Academy as owned by Technowiz Private Limited and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the sellers or other service providers and specifically waiver any claims or demands that you may have in this behalf under any statute, contract or otherwise.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          LOSSES
        </Typography>
        <Typography variant="body1" paragraph>
          We will not be responsible for any business or personal losses (including but not limited to loss of profits, revenue, contracts, anticipated savings, data, goodwill, or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when you commenced using the Website.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          AMENDMENTS TO CONDITIONS OR ALTERATIONS OF SERVICE AND RELATED PROMISE
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to make changes to the Website, its policies, these terms and conditions and any other publicly displayed condition or service promise at any time. You will be subject to the policies and terms and conditions in force at the time you used the Website unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          EVENTS BEYOND OUR CONTROL
        </Typography>
        <Typography variant="body1" paragraph>
          We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          WAIVER
        </Typography>
        <Typography variant="body1" paragraph>
          You acknowledge and recognize that we are a private commercial enterprise and reserve the right to conduct business to achieve our objectives in a manner we deem fit. You also acknowledge that if you breach the conditions stated on our Website and we take no action, we are still entitled to use our rights and remedies in any other situation where you breach these conditions.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          TERMINATION
        </Typography>
        <Typography variant="body1" paragraph>
          In addition to any other legal or equitable remedies, we may, without prior notice to you, immediately terminate the Terms and Conditions or revoke any or all of your rights granted under the Terms and Conditions. Upon any termination of this Agreement, you shall immediately cease all access to and use of the Website and we shall, in addition to any other legal or equitable remedies, immediately revoke all password(s) and account identification issued to you and deny your access to and use of this Website in whole or in part. Any termination of this agreement shall not affect the respective rights and obligations (including without limitation, payment obligations) of the parties arising before the date of termination. You furthermore agree that the Website shall not be liable to you or to any other person as a result of any such suspension or termination. If you are dissatisfied with the Website or with any terms, conditions, rules, policies, guidelines, or practices in operating the Website, your sole and exclusive remedy is to discontinue using the Website.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          GOVERNING LAW AND JURISDICTION
        </Typography>
        <Typography variant="body1" paragraph>
          These terms and conditions are governed by and construed in accordance with the laws of The Islamic Republic of Pakistan. You agree, as we do, to submit to the exclusive jurisdiction of the courts in Pakistan.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          DISCLAIMERS
        </Typography>
        <Typography variant="body1" paragraph>
          The service and parts are made available on an "as is," "as available," and "with all faults" basis, without any warranties or conditions, express, implied or statutory. You use the services entirely at your own risk. We do not provide, and specifically disclaim, any representation or warranty of any kind to you or any other person or entity, including, but not limited to, any express or implied warranty (i) of merchantability or fitness for a particular purpose; (ii) of informational content or accuracy; (iii) of non-infringement; (iv) of quiet enjoyment; (v) of title; (vi) that the website will operate error free, or in an uninterrupted fashion; (vii) that any defects or errors in the website will be corrected; (viii) that services offered will be available in all markets; or (ix) that the website is compatible with any particular hardware or software. The website makes no representation, warranty, or guarantee regarding the reliability, timeliness, quality, suitability, or availability of the services or any services or goods requested through the use of the website, or that the services will be uninterrupted or error-free. The website does not guarantee the quality, suitability, safety or ability of third party services providers or vendors. You agree that the entire risk arising out of your use of the services, and any service or good requested in connection therewith, remains solely with you, to the maximum extent permitted under applicable law.
        </Typography>
      </Paper>
    </Container>
  );
}

export default Terms;